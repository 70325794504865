$font-icon:" Font Awesome 5 Free";
$main_border:1px solid #ebebeb;
@import "animate";
@import "fontawesome-all";  
@import "ionicons"; 
@import "linearicons"; 
@import "pe-icon-7-stroke"; 
@import "plaza-icon";
@mixin pos-transition($number) {
	-webkit-transition: all $number linear;
	-moz-transition: all $number linear;
	-ms-transition: all $number linear;
	-o-transition: all $number linear;
	transition: all $number linear;   
}
@mixin pos-transform($number) {
	-moz-transform: scale3d($number,$number,$number);
	-webkit-transform: scale3d($number,$number,$number);
	-o-transform: scale3d($number,$number,$number);
	-ms-transform: scale3d($number,$number,$number);
	transform: scale3d($number,$number,$number); 
}
@function get1919-vw($target) {
  // 1 vw is equal to 1% of the viewport width 
  $vw-context: (1919 * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;  
}  
@function get1199-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: (1199 * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}
@function get991-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: (991 * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}
@function get767-vw($target) {
  // 1 vw is equal to 1% of the viewport width 
  $vw-context: (767 * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw; 
}
@function get480-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: (480 * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}
@mixin placeholder {
  &::-moz-placeholder { @content }
  &::-webkit-input-placeholder { @content }
  &:-ms-input-placeholder { @content }
}
 
@mixin placeholder-style {
  color:#ffffff;
}
.animatetab .owl-carousel .owl-item {
	-moz-animation: zoomIn 500ms ease;
	-o-animation: zoomIn 500ms ease;
	-webkit-animation: zoomIn 500ms ease;
	animation: zoomIn 500ms ease;
}
/* css jquery */
/* Slider */
 .slick-loading .slick-list {
     background: #fff url('./ajax-loader.gif') center center no-repeat;
}
/* Icons */
 @font-face {
     font-family: 'slick';
     font-weight: normal;
     font-style: normal;
     src: url('./fonts/slick.eot');
     src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}
/* Arrows */
 .slick-prev, .slick-next {
     font-size: 0;
     line-height: 0;
     position: absolute;
     top: 50%;
     display: block;
     width: 20px;
     height: 20px;
     padding: 0;
	 z-index:1;
     -webkit-transform: translate(0, -50%);
     -ms-transform: translate(0, -50%);
     transform: translate(0, -50%);
     cursor: pointer;
     color: transparent;
     border: none;
     outline: none;
     background: transparent;
}
 .slick-vertical {
	 .slick-prev, .slick-next {
		 left: 50%;
		 -webkit-transform: translate(-50%, 0);
		 -ms-transform: translate(-50%, 0);
		 transform: translate(-50%, 0);
	}
	 .slick-prev {
		 top:-25px;
	}
	 .slick-next {
		 bottom:-25px;
		 top:auto;
	}
	.slick-prev:before {
		 content: "\ea35";
	}
	 [dir='rtl'] .slick-prev:before {
		 content: "\ea32";
	}
	 .slick-next:before {
		 content: "\ea32";
	}
	 [dir='rtl'] .slick-next:before {
		 content: "\ea35";
	}
}
 .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
     color: transparent;
     outline: none;
     background: transparent;
}
 .slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
     opacity: 1;
}
 .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
     opacity: .25;
}
 .slick-prev:before, .slick-next:before {
	font-family: 'plaza-icon';
     font-size: 20px;
     line-height: 1;
     opacity: .75;
     color: white;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}
 .slick-prev {
     left: -25px;
	 @media (max-width: 991px) {
		left:-15px;  
	}
}
 [dir='rtl'] .slick-prev {
     right: -25px; 
     left: auto;
	 @media (max-width: 991px) {
		right:-15px;
	}
}
 .slick-prev:before {
	 content: "\ea33";
}
 [dir='rtl'] .slick-prev:before {
     content: '→';
	 content: "\ea34";
	 
}
 .slick-next {
     right: -25px;
	@media (max-width: 991px) {
		right:-15px;
	}
}
 [dir='rtl'] .slick-next {
     right: auto; 
     left: -25px;
	 @media (max-width: 991px) {
		left:-15px;
	}
}
 .slick-next:before {
     content: '→';
	 content: "\ea34";
}
 [dir='rtl'] .slick-next:before {
     content: "\ea33";
}
/* Dots */
 .slick-dotted.slick-slider {
     margin-bottom: 30px;
}
 .slick-dots {
     position: absolute;
     bottom: -25px;
     display: block;
     width: 100%;
     padding: 0;
     margin: 0;
     list-style: none;
     text-align: center;
}
 .slick-dots li {
     position: relative;
     display: inline-block;
     width: 20px;
     height: 20px;
     margin: 0 5px;
     padding: 0;
     cursor: pointer;
}
 .slick-dots li button {
     font-size: 0;
     line-height: 0;
     display: block;
     width: 20px;
     height: 20px;
     padding: 5px;
     cursor: pointer;
     color: transparent;
     border: 0;
     outline: none;
     background: transparent;
}
 .slick-dots li button:hover, .slick-dots li button:focus {
     outline: none;
}
 .slick-dots li button:hover:before, .slick-dots li button:focus:before {
     opacity: 1;
}
 .slick-dots li button:before {
     font-family: 'slick';
     font-size: 6px;
     line-height: 20px;
     position: absolute;
     top: 0;
     left: 0;
     width: 20px;
     height: 20px;
     content: '•';
     text-align: center;
     opacity: .25;
     color: black;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}
 .slick-dots li.slick-active button:before {
     opacity: .75;
     color: black;
}
/* Slider */
 .slick-slider {
     position: relative;
     display: block;
     box-sizing: border-box;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     -webkit-touch-callout: none;
     -khtml-user-select: none;
     -ms-touch-action: pan-y;
     touch-action: pan-y;
     -webkit-tap-highlight-color: transparent;
}
 .slick-list {
     position: relative;
     display: block;
     overflow: hidden;
     margin: 0;
     padding: 0;
}
 .slick-list:focus {
     outline: none;
}
 .slick-list.dragging {
     cursor: pointer;
     cursor: hand;
}
 .slick-slider .slick-track, .slick-slider .slick-list {
     -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
     -ms-transform: translate3d(0, 0, 0);
     -o-transform: translate3d(0, 0, 0);
     transform: translate3d(0, 0, 0);
}
 .slick-track {
     position: relative;
     top: 0;
     left: 0;
     display: block;
     margin-left: auto;
     margin-right: auto;
}
 .slick-track:before, .slick-track:after {
     display: table;
     content: '';
}
 .slick-track:after {
     clear: both;
}
 .slick-loading .slick-track {
     visibility: hidden;
}
 .slick-slide {
     display: none;
     float: left;
     height: 100%;
     min-height: 1px;
}
 [dir='rtl'] .slick-slide {
     float: right;
}
 .slick-slide img {
     display: block;
}
 .slick-slide.slick-loading img {
     display: none;
}
 .slick-slide.dragging img {
     pointer-events: none;
}
 .slick-initialized .slick-slide {
     display: block;
}
 .slick-loading .slick-slide {
     visibility: hidden;
}
 .slick-vertical .slick-slide {
     display: block;
     height: auto;
     border: 1px solid transparent;
}
 .slick-arrow.slick-hidden {
     display: none;
}
 .slick-lightbox{
    position:fixed;
    top:0;
    left:0;
    z-index:9999;
    width:100%;
    height:100%;
    background:black;
    -webkit-transition:opacity 0.5s ease;
    transition:opacity 0.5s ease
}
.slick-lightbox .slick-loading .slick-list{
    background-color:transparent
}
.slick-lightbox .slick-prev{
    left:15px
}
.slick-lightbox .slick-next{
    right:15px
}
.slick-lightbox-hide{
    opacity:0
}
.slick-lightbox-hide.slick-lightbox-ie{
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter:alpha(opacity=0)
}
.slick-lightbox-hide-init{
    position:absolute;
    top:-9999px;
    opacity:0
}
.slick-lightbox-hide-init.slick-lightbox-ie{
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter:alpha(opacity=0)
}
.slick-lightbox-inner{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%
}
.slick-lightbox-slick-item{
    text-align:center;
    overflow:hidden
}
.slick-lightbox-slick-item:before{
    content:'';
    display:inline-block;
    height:100%;
    vertical-align:middle;
    margin-right:-0.25em
}
.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption{
    position:absolute;
    bottom:0;
    left:0;
    text-align:center;
    width:100%;
    margin-bottom:20px
}
.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption{
    display:block;
    text-align:center
}
.slick-lightbox-slick-item-inner{
    display:inline-block;
    vertical-align:middle;
    max-width:90%;
    max-height:90%
}
.slick-lightbox-slick-img{
    margin:0 auto;
    display:block;
    max-width:100%;
    max-height:100%    
}
.slick-lightbox-slick-caption{
    margin:10px 0 0;
    color:white
}
.slick-lightbox-close{
    position:absolute;
    top:15px;
    right:15px;
    display:block;
    height:50px;
    width:50px;
    line-height:0;
    font-size:0;
    cursor:pointer;
    background:transparent;
    color:transparent;
    padding:0;
    border:none
}
.slick-lightbox-close:focus{
    outline:none
}
.slick-lightbox-close:before{
    font-family:"slick";
    font-size:50px; 
    line-height:1;
    color:white;
    opacity:0.85;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    content:'×'; 
}
.slick-lightbox-close:hover:before{
    opacity:1;
}
.slick-lightbox  .slick-prev,.slick-lightbox  .slick-next {
    width: 60px;
    height: 60px;
}
.slick-lightbox  .slick-prev:before,.slick-lightbox  .slick-next:before {
    font-size: 60px; 
}
@media (max-width:767px ) {
	.slick-lightbox  .slick-prev,.slick-lightbox  .slick-next {
		width: 30px; 
		height: 30px; 
	}
	.slick-lightbox  .slick-prev:before,.slick-lightbox  .slick-next:before {
		font-size: 30px; 
	}
}
/** * EasyZoom core styles */
 .easyzoom {
     position: relative;
     display: inline-block;
}
 .easyzoom img {
     vertical-align: bottom;
     max-width:none ;
}
 .easyzoom.is-loading img {
     cursor: progress; 
} 
 .easyzoom.is-ready img {
     cursor: crosshair;
}
 .easyzoom.is-error img {
     cursor: not-allowed;
}
 .easyzoom-notice {
     position: absolute;
     top: 50%;
     left: 50%;
     z-index: 150;
     width: 10em;
     margin: -10px 0 0 -50px;
     line-height:20px;
     text-align: center;
     background: #FFF;
     box-shadow: 0 0 10px #888;
}
 .easyzoom-flyout {
     position:absolute;
     z-index: 100;
     overflow: hidden;
     background: #FFF;
}
/** * EasyZoom layout variations */
 .easyzoom--overlay .easyzoom-flyout {
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
}
 .easyzoom--adjacent .easyzoom-flyout {
     top: 0;
     left: 100%;
     width: 100%;
     height: 100%;
     margin-left:20px;
}
 
/* end css jquery */

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
	width: 100%;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }

  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
	padding:0 15px;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
	&:hover {
		z-index:2;
	}
	}
  .owl-carousel .owl-item img {
    display: block;
    max-width: 100%;
    -webkit-transform-style: preserve-3d; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    display: none; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }
  
  input:focus, button:focus, select:focus, textarea:focus {
  text-decoration: none !important;
  box-shadow: none !important;
  outline:0 !important;
}

a {
  &:hover, &:focus {
    outline: 0 !important;  
   text-decoration: underline;
  }
}
.btn-default, .btn {
  &:hover, &:focus, &:active, &.active {
    outline: 0 !important;
    text-decoration: none !important;
    box-shadow: none !important; 
  }
}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.btn-primary.dropdown-toggle.focus, .open>.btn-primary.dropdown-toggle:focus, .open>.btn-primary.dropdown-toggle:hover {
    border-color: transparent;
	border:none;
	color:#ffffff;
}
.btn-secondary, .btn-tertiary ,.btn-primary{
    text-transform: capitalize;
	font-weight: 400;
    font-size: 14px;
    border:none;
	line-height:25px;
	box-shadow:none;
	padding: 5px 20px;
    border-radius: 5px;
	display:inline-block;
	background:#292929;
	color:#ffffff;

}
.btn-secondary:hover, .btn-tertiary:hover,.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
    border-color: transparent;
	border:none;
	color:#ffffff;
}
a {

  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none;
}
/* edit from here */
.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a{
	font-size: 12px;
}
.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a.ui-state-active{
	margin: 0;
	background: none;
	border: 0;
	cursor: pointer;
}

button {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.product-flag{
	margin: 0;
}

@media (min-width:1920px){ 
	.modal {
		top:50%; 
		bottom:auto;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.modal-dialog {
		margin: 0 auto;    
	} 
}
/* edit theme */

body {
	line-height:24px;  
	
}
* {
	&:focus {
		outline:none !important;
	}
}
b, strong {
	font-weight:600;
	
}
img {
	max-width:100%;
	margin:auto;
	height:auto;
	
}
ol, ul, dl {
    margin: 0;
	padding:0;
	list-style-type:none;
}
i, cite, em, var, address, dfn {
    font-style: normal;
}
p {
	margin:0;  
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-size:20px;
	text-transform:capitalize;
    color:#292929;
	font-weight:600; 
	line-height:24px; 
}
a {
	color:#555555; 
	@include pos-transition(300ms); 
}
/* custom page */
.card  {
	box-shadow:none;
	border:none;
	padding:0; 
}
.page-header ,.card-block{
	h1 {
	    color: #292929;
		text-align: left;
		font-size: 20px;
		font-style: normal;
		text-transform: none;
		margin:0; 
	}
}
.page-content.page-cms {
    padding: 1.25rem 0;
}
.contact-form {
    color:#292929; 
}
.form-control {
    background-color:#ffffff;
    color: #292929;
    border:$main_border;
	font-size:14px;
}
.form-control:focus {
    color: #292929;
    border-color:#ebebeb;  
}
.form-control:focus,.input-group.focus {
    outline:0;
}
.input-group .input-group-btn>.btn[data-action=show-password] {
  &:hover {
  }
}
.form-control-label {
    font-size: 13px;
    text-align: left; 
    color: #292929;
}
.input-group .input-group-btn>.btn {
    padding: .67rem 1rem;
}
.sitemap h2 {
    color: #292929;
    text-transform: capitalize;
    font-size: 16px;
    font-weight:600;
    border-bottom: $main_border;
}
.sitemap ul li {
    font-size: 13px;
	&:before {
		font-family: 'plaza-icon';
		content: "\e930"; 
		font-size: 14px;
		display: inline-block;
		margin-right: 5px;   
	}
}
#stores .page-stores {
  width:100%;
}
#stores .page-stores .store-item {
    padding:0;
}
#stores .page-stores .store-item {
	border-bottom:$main_border;
	&:last-child {
		border-bottom:none; 
	}
	
	.h3.card-title {
		font-size:16px;
		color:#292929;
		text-transform:capitalize;
	}
}
#stores .page-stores .store-item-container .store-description {
    font-size: 13px;
    color: #666;
}
#stores .page-stores .store-item-container ul {
    margin-bottom: 0;
    font-size: 13px;
}
#stores .page-stores .store-item-container .divide-left {
    border-left: $main_border; 
}
.page-addresses .address, .page-authentication #content {
    box-shadow: none;
    background: #fff;
	border:$main_border;
}
.page-authentication #content {
   max-width:100%;
}
.page-customer-account #content {
    box-shadow: none;
	border:$main_border;
    font-size:14px;
    color: #292929;
}
.page-customer-account #content .no-account {
    text-align: center;
    font-size: 14px;
}
.custom-checkbox input[type=checkbox]+span {
    margin-top: 0; 
}
.label, label {
    font-size: 14px;
    color: #292929;
}
.page-my-account #content .links a span.link-item {
    box-shadow: none;
    border:$main_border;
    font-size: 14px;
}
.separator {
    border-top:$main_border;   
}
.cart-grid-body .cart-overview {
    padding: 1rem 0;  
}
.cart-summary,#js-checkout-summary{
	border:$main_border;
}
.cart-summary-line .label,.cart-summary .label,.cart-summary-line.cart-summary-subtotals .label, .cart-summary-line.cart-summary-subtotals .value  {
    text-transform: capitalize;
    font-weight:600; 
}
.product-line-info a.product_name {
	line-height: 20px;
	display: block;
	overflow: hidden;
	font-size: 14px;
	font-weight:500;
	margin-bottom: 13px; 
	float:left; 
	color:#292929;
}
.product-line-info  .regular-price {
	margin:0 5px; 
}
.cart-summary-line .value {
    color: #292929;
    font-weight:600;
    font-size: 16px;
}
.cart-summary-products p {
	font-weight:600;
}
.cart-grid-body .card-block {
    padding: 0;
}
.cart-item {
	border-bottom:$main_border;
}
body#checkout section.checkout-step {
    box-shadow:none;
    border:$main_border; 
	margin-bottom:10px; 
}
body#checkout section.checkout-step .step-title {
    text-transform: capitalize;
}
body#checkout .custom-checkbox span {
	margin-top:0; 
}
body#checkout section.checkout-step .address-item.selected {
    border: 2px solid #ebebeb;
}
body#checkout section.checkout-step .address-alias {
    vertical-align: -2px;
    font-size: 14px;
    margin-left: 5px;
}
body#checkout section.checkout-step .address { 
    line-height: 24px;
}
.checkout-inner {
	border:$main_border;
}
.block-reassurance-item {
	.h6 {
		font-size:14px;
	}
}
#block-reassurance li {
    border-bottom: $main_border;
}
.page-addresses .address .address-footer {
   border-top:$main_border;
}
/* end custom page */

@media (min-width:1200px ) { 
 .container-fluid {
  padding:0 30px;   
 }
 
}
@media (min-width:1600px ) { 
 .container-fluid {

 }
 
}
@media (min-width:1920px ) { 
 .container-fluid {
	max-width:1920px;  
 }
 
}
@media (max-width:1199px ) {
 .container {
  width:auto;    
 }
}
@media (min-width: 1200px){
	.container {
		width: 1200px; 
	}
}
/* header */

main {
	overflow:hidden;
	position:relative; 
}
@media (min-width:992px ) {
	#index #header {
		
	}
}
#header {
	background:#ffffff; 
	.header-top {
		position:relative;  
		padding:25px 0;     
		.container {
			> .row { 	
				display:flex;
				align-items: center; 
			} 
		}
		&.scroll-menu { 
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1001;
			border: none;
			margin: auto; 
			padding:10px 0; 
			@include pos-transition(300ms); 
			background: rgba(255,255,255,.8);
			-webkit-box-shadow: 0 8px 6px -6px rgba(0,0,0,.4);
			-moz-box-shadow: 0 8px 6px -6px rgba(0,0,0,.4);
			box-shadow: 0 8px 6px -6px rgba(0,0,0,.4);
			&:hover {
				background: rgba(255,255,255,1);  
			}
			#_desktop_megamenu .pos-menu-horizontal .menu-content .menu-dropdown {
				padding-top:10px;
			}
			.blockcart .popup_cart {
				padding: 10px 0 0 0;   
			}
			.block_setting .content_setting {
				margin-top: 10px;  
			}
		}			
		
	}
	.header-bottom { 
		margin-bottom:20px;
		
	}
	
}
#mobile_top_menu_wrapper {
	display: block !important;
	overflow: hidden;
	position: fixed; 
	top: 0;
	left:0;
	height: 100%;
	width: 100%;  
	max-width:350px;
	z-index: 99;
	margin: 0;
	padding:0;
	webkit-transform: translate(-400px, 0px);
    -moz-transform: translate(-400px, 0px);
    -ms-transform: translate(-400px, 0px);
    -o-transform: translate(-400px, 0px);
    transform: translate(-400px, 0px);
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
    -moz-transition-duration: .9s;
    -o-transition-duration: .9s;
    -webkit-transition-duration: .9s;
    transition-duration: .9s;
    overflow: auto;
}
#mobile_top_menu_wrapper.box-menu{
	-webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}
#mobile_top_menu_wrapper .top-header-mobile {
	display:inline-block;
	width:100%;
	text-align:center;
	padding:10px 0; 
}
#header .header-nav #menu-icon {
   color:#292929;
   height: 40px;
   line-height: 40px;
   width:40px;
   text-align:center; 
   border-radius:100%;
   margin-right:5px; 
   &:hover {
	   background:#292929;
	   color:#ffffff; 
   }
}
#header .header-nav #menu-icon i{
   font-size:19px;
   line-height:40px; 
}
 .open-menu:after {  
    top: 0;
	left:0;
    content: "";
    width: 100%;
    display: block;
    position: fixed;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
	z-index:99;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.3, 1);
    transition-delay: 0.1s; 
} 
.menu-close {
	background:#333333;
	color:#ffffff;
	line-height:40px;
	height:40px; 
	padding:0 20px;
	font-size:16px;
	font-weight:600;
	text-transform:uppercase; 
	pointer-events: none;
}
.menu-close i {
	line-height:40px; 
    display: block;
    height: 40px;
    pointer-events: visible;
}
.menu-tabs {
	width:100%;
	height:100%;
}
#_mobile_currency_selector,#_mobile_language_selector {
	display:inline-block; 
	width:100%;
}
.selector-mobile {
	img {
		vertical-align: 0px;
		margin-right: 5px;
	}
	button {
		background: transparent;
		border: none;
		border-top: 1px solid #eeeeee;
		width: 100%;
		text-align: left;
		display: block;
		color: #333333;
		font-size: 13px;
		font-weight: 400;
		padding: 5px 20px;
		line-height: 30px;
		cursor:pointer;
		text-transform: uppercase; 
		
		i{
			position: absolute;
			right: 20px;
			top: 13px;
			font-size: 18px;
			color: #333333; 
		}
	}
	.dropdown-menu {
		position: static;
		border: 0;
		padding: 0;
		margin: 0;
		float:none;
		li {			
			border-top: 1px solid #eeeeee;
			a {
				display: block;
				color: #333333;
				font-size: 12px;
				font-weight: 400;
				padding: 5px 20px;
				line-height: 30px;
				text-transform: uppercase; 
			}
		}
	}

} 
.js-top-menu-bottom {
	display:inline-block;
	width:100%; 
	position:relative;  
}
	/* nav */
	#header .header-nav {
		position:relative; 
		z-index:9;
		margin:0;
		border:none;
		.container {
			position:relative;
			&:before {
				content:"";
				position:absolute;
				bottom:0;
				left:15px;
				right:15px;
				border-bottom:$main_border; 				
			}
		}
		@media (max-width:991px ) {
			padding:0;
			border:none; 
			.top-logo {
				padding:10px 0; 
			}
		} 
	}
	.header-nav .mobile {
		> .row-mobile {	
			@media (min-width:576px ) {
			display: flex;
			align-items:center;
			}
			.mobile-bottom {
				display: flex;
				justify-content: space-around;	 
				align-items:center;	
			} 
		}
	}
	
	/* end nav */
	/* selector */
	.selector-block {
		float:right;
		padding:0 20px;
		line-height:14px;
		height:14px;
		color:#555555;
		border-right:1px solid #bcbcbc;
		&.language-selector-wrapper {
			@media (min-width:992px ) {
				padding-right:0;
				border:none; 
			}
		}
		.selector-label {
			color:#555555;
			line-height:20px;
			text-transform:capitalize;
			font-size:14px;
			margin-right:5px;
			display:none;
		}
		.localiz_block {
			margin:0 !important;
			display:inline-block;
			padding:0 !important; 
			button,.current-block {
				font-weight: 400;  
				color:#555555;
				background:transparent;
				text-shadow: none;
				cursor: pointer;
				line-height:14px;
				font-size:14px;	
				display: inline-block;
				text-transform:capitalize;
				@include pos-transition(300ms);
				
				i {
					font-size:14px;
					vertical-align:-4px;
				}
				img {
					margin-right:5px;
					vertical-align: -1px;
				}
				
			}
			.dropdown-menu {
				margin:0;
				top:29px; 
				left:auto; 
				right:0;
				min-width:130px;
				overflow:hidden;
				padding:0 15px;
				background:#ffffff;
				border-radius: 0;
				border:none;
				border:$main_border;
				li {
					padding:0 ;
					@include pos-transition(300ms);
					&:last-child {
						.dropdown-item {
							border-bottom:none;
						}
					}
					.dropdown-item {
						padding:0 10px;
						color: #555555;
						line-height: 40px;
						font-size: 14px;
						border-bottom:$main_border;
						img {
							display: inline-block;
							margin-right: 5px;
						}
					}
					
				}
			} 
		}
	}
	#_mobile_contact .contact-link {
		float: none;
		display: block;
		margin-bottom: 5px; 
	}
	
	#_mobile_user_info .user-info-block .localiz_block button i {
		font-size:24px;
		color:#333; 
		height:50px;
		line-height:50px;
	}
	#_mobile_user_info .user-info-block  .localiz_block .dropdown-menu {
		top:100%;
	}
	/* end selector */
/* setting */
.block_setting  {
	float:right;
	&:hover {
		.icon-Settings {
			color:#ffffff;
			background:#000000;
		}
	}
	.icon-Settings {
		font-size:19px; 
		height:40px;
		line-height:40px;
		width:40px;
		border-radius:100%;
		color:#292929;
		cursor:pointer;
		display:inline-block;
		text-align:center; 
		&:before {
			vertical-align:2px; 
		}		
	}
	.content_setting  {
		@media (min-width:992px) {
		margin-top:15px;
		}		
		min-width:270px;
		padding:30px;
		border:$main_border; 
		background:#ffffff;
		border-radius:0;
		position:absolute;
		right:0;
		left:auto;
		top:100%;
		.title_setting {
			font-size:14px;
			text-transform:uppercase;
			color:#292929;
			line-height:1; 
			font-weight:500; 
			margin-bottom:15px;	
		}
		.info_setting {
			margin:0;
			text-align:left;
			padding-bottom:20px;
			margin-bottom:30px;
			border-bottom:$main_border; 
			&:last-child {
				border:none;
				padding:0;
				margin:0; 
			}
			ul li {
				a {
					color:#292929;
					font-size:12px;
					line-height:36px;  
					padding:0;
					img {
						vertical-align: -1px;
						display: inline-block;
						margin-right: 7px;
					}
				}
			}
			&#language_selector,&#currency_selector {
				ul li {
					display:inline-block;
					vertical-align:top;
					position:relative;
					margin-right:18px;
					&:last-child {
						margin:0;
						&:after {
							display:none;
						}
					}
					&:after {
						content:"-";
						color:#959595;
						position: absolute;
						right: -15px;
						top: 50%;
						line-height: 1;
						-moz-transform: translateY(-50%);
						-webkit-transform: translateY(-50%);
						-o-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}
/* end setting */	
.header-top  {
	#_desktop_logo {
		img {
			margin:0;    
		}
	}
}	
	
/* end header */
/* module user */
.user-info-block {
	float:right;
	margin-right:10px;	 
	button {
		font-size:26px;
		line-height:43px;
		height:43px;
		padding-right:10px;
		color:#292929; 
		cursor:pointer; 
		i {
			display:block;
			line-height:43px; 
		}
	}
	 .dropdown-menu {
		margin: 0;
		top: 100%;
		right: 0;
		left:auto;
		min-width: 130px;
		overflow: hidden;
		padding: 0 15px;
		background: #ffffff;
		border-radius: 0;
		border: none;
		border:$main_border;
		@media (max-width:479px) {
			right:50%;
			-moz-transform: translateX(50%);
			-webkit-transform: translateX(50%);
			-o-transform: translateX(50%);
			-ms-transform: translateX(50%);
			transform: translateX(50%); 
		}
		li {
			a {
				padding: 0 10px;
				color: #555555;
				line-height: 40px;
				font-size: 14px;
				border-bottom: $main_border; 
			}
		}
	}
}
/* end module user */
/* module contact */
#_desktop_contact_link {
	.contact-link {
		position: absolute;
		bottom: -40px;
		right: 15px;
		z-index: 1; 
		.phone {
			float:right;
			padding-left:40px; 
			line-height:25px; 
			position:relative; 
			&:before {
				content:"";
				display:inline-block; 
				width:32px;
				height:32px;
				background: url(../img/icon_contact.png) no-repeat;
				position:absolute;
				left:0;
				top:0;
			} 
			a {			
				color:#292929;
				display:block;
				font-size:15px;
				line-height:18px;
			}
			span {
				display:block;
				font-size:12px;
				color:#929292;  
				line-height:14px; 
			}
		}
	}
}
/*end module contact */
.pos-loading {
	background:#f6f6f6; 
	position:relative;
	span {
		width: 20px;
		height: 20px;
		position: absolute;
		z-index: 99;
		background: url(../img/preloader.gif) no-repeat;
		top: 50%;
		left: 50%;
		margin:-10px 0 0 -10px;
	}
	img {
		opacity:0;  
	}
} 
.nivo-html-caption {
    display: none;
}
@-webkit-keyframes myfirst {
		from {width: 0;}
		to {width: 100%;}
	}
	@keyframes myfirst {
		from {width: 0;}
		to {width: 100%;}
	}
	.timethai.pos_hover{ 
		-webkit-animation-play-state: paused;
		animation-play-state: paused;
		
}

.slideshow_container  .pos-slideshow{
	position: relative; 
	margin-bottom:70px; 
	@media (max-width:767px ) { 
		margin-bottom:30px; 
	}
	&:hover .nivo-directionNav a {
		opacity:1;
		@include pos-transition(300ms);
		&.nivo-prevNav{
			left:20px;
		}
		&.nivo-nextNav{
			right:20px;
		}
	}
}
.slideshow_container .pos-slideshow .nivo-directionNav a {
	    display: block;
		width:50px;
		height:50px;
		line-height:50px;
		color:#e2e2e2;
		text-align: center;
		font-size:0;
		position: absolute;
		z-index: 8;
		cursor: pointer;
		top: 50%;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		opacity:0;
		 -webkit-transition: none;
		-moz-transition: none;
		-o-transition: none;
		transition: none;
		i {
			font-size:50px;
		}
		@media (max-width:767px) {
			display: none;
		}
		
		&.nivo-prevNav{
			left:0;
		
		}
		&.nivo-nextNav{
			right:0;
			
		}
}
.slideshow_container .pos-slideshow .nivo-controlNav { 
		position: absolute;
		bottom:30px;
		@media (max-width:1199px) {
			bottom:get1199-vw(30px);
		}
		left:50%;
		-moz-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		text-align:center;
		display:inline-block;
		z-index: 8;
		@media (max-width: 767px) {
			
		}
		a{
			display: inline-block;
			margin: 0 3px;
			cursor: pointer; 
			font-size: 0;
			width: 14px;
			height: 14px;
			background:#b0b0b0;
			border:2px solid transparent;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			border-radius: 100%;
			@include pos-transform(0.7);
			@include pos-transition(300ms);	
			&.active,&:hover{
				@include pos-transform(1);
				background:transparent; 
			}
		}
}

.banner7-des {
	position:absolute;
	left:0; 
	top:50%;
	right:0;
	z-index:8;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	line-height:1;
	.position_left {
		text-align:left;
	}
	.position_left {
		text-align:left;
	}
	.position_center {
		text-align:center;
	}
	.position_right { 
		text-align:right;
	}
	.info {
		display:inline-block; 
		padding-left:get1919-vw(55px);	 		
	}
	p,h2,h3,h4,h5 {
		margin:0;
	}
}

.desc_slideshow {
	h2 {
		font-size:48px;		
		color:#292929;
		font-weight:500;
		line-height:1;
	}
	h3{
		font-size:38px;	  
		color:#292929;
		font-weight:500;
		line-height:1;
	}
	h4{
		font-size:28px;	
		color:#292929;
		font-weight:500;
		line-height:1;
	}
	h5{
		font-size:18px;	
		color:#292929;
		font-weight:400;
		line-height:1;
		text-transform:uppercase;
		margin-bottom:20px;	
	}
	p{
		font-size:16px;	 
		color:#666666;
		font-weight:400;
		margin-top:30px;   
	}
	.readmore  {
		display:inline-block;
		margin-top:45px;  
		@include pos-transition(300ms);
		position:relative;
		font-size:12px;	
		font-weight:600;
		color:#292929;
		line-height:16px; 
		border:2px solid #292929;	
		background:transparent;	
		text-transform:capitalize;
		display:inline-block;
		border-radius:0; 
		padding:20px 55px;
		text-transform:uppercase;
		&:hover {
			background:#292929;
			color:#ffffff;
		}
		
	}
	.transition_slide_0 {
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
	}
	.transition_slide_1 {
		-webkit-animation-duration: 1.3s;
		animation-duration: 1.3s;
	}
	.transition_slide_2 {
		-webkit-animation-duration: 1.6s;
		animation-duration: 1.6s;
	}
	.transition_slide_3 {
		-webkit-animation-duration: 1.9s;
		animation-duration: 1.9s;
	}
	.transition_slide_4 {
		-webkit-animation-duration: 2.2s;
		animation-duration: 2.2s;
	}
	.transition_slide_5 {
		-webkit-animation-duration: 2.5s;
		animation-duration: 2.5s;
	}
	.transition_slide_6 {
		-webkit-animation-duration: 2.8s;
		animation-duration: 2.8s; 
	}
	.transition_slide_7 {
		-webkit-animation-duration: 3.1s;
		animation-duration: 3.1s; 
	}
	.transition_slide_8 {
		-webkit-animation-duration: 3.4s;  
		animation-duration: 3.4s; 
	}
	
}

/* responsive slideshow */
@media (max-width: 1199px) {
	
	.desc_slideshow {
		h2 {
			font-size:get1199-vw(48px);		

		}
		h3{
			font-size:get1199-vw(38px);	  

		}
		h4{
			font-size:get1199-vw(28px);	

		}
		h5{
			font-size:get1199-vw(18px);	
			margin-bottom:get1199-vw(20px);	
		}
		p{
			font-size:get1199-vw(16px);	 
			margin-top:get1199-vw(30px);   
		}
		.readmore  {
			margin-top:get1199-vw(45px);  
			font-size:get1199-vw(12px);	
			line-height:get1199-vw(16px);    
			padding:get1199-vw(20px) get1199-vw(55px);
			
		}
	}
}
 @media (max-width: 991px) { 
	
	.desc_slideshow {
		h2 {
			font-size:get991-vw(40px);		

		}
		h3{
			font-size:get991-vw(30px);	  

		}
		h4{
			font-size:get991-vw(20px);	

		}
		h5{
			font-size:get991-vw(14px);	
			margin-bottom:get991-vw(20px);	
		}
		p{
			font-size:get991-vw(14px);	 
			margin-top:get991-vw(30px);   
		}
		.readmore  {
			margin-top:get991-vw(40px);  
			font-size:get991-vw(12px);	
			line-height:get991-vw(16px);    
			padding:get991-vw(15px) get991-vw(50px); 			
		}
	}
} 
/* end responsive slideshow */


/* end module slideshow */
/* module advertising */
.advertising-block {
	position: relative;
    margin-bottom: 50px;  
    text-align: center;
	overflow:hidden;
	a {
		img {
			width: 100%;
			height:auto;
		}
		&:before {
			background: rgba(255, 255, 255, 0.2);
			bottom: 0;
			content: "";
			left: 50%;
			position: absolute;
			right: 51%;
			top: 0;
			opacity: 1;
			
		
		}
		&:after {
			    background: rgba(255, 255, 255, 0.2);
				bottom: 50%;
				content: "";
				left: 0;
				position: absolute;
				right: 0;
				top: 50%;
				opacity: 1;
			
		}
	}
	&:hover a:before{
			left: 0;
			right: 0;
			opacity: 0;
			@include pos-transition(900ms);
		}
	&:hover  a:after{
		top: 0;
		bottom: 0;
		opacity: 0;
		@include pos-transition(900ms);
	}
}
/* end module advertising */
/* module tags */
	#tags_block {
		padding-bottom:50px;
		border-bottom:$main_border;    
		margin-bottom: 50px;
		display: inline-block;
		width: 100%;
		h2 {
			font-size: 20px;
			line-height: 1;
			font-weight:500;
			color: #292929;
			margin-bottom: 20px;
			position: relative;
			display: block; 
			text-transform: uppercase; 
		}
		.tags_block {
			line-height:1;
			li {
				line-height:1;
				float:left;
				list-style: none;
				&:first-child {
					a {
						margin-left:0; 
					}
				}
				a {
					display: block;
					float: left;
					padding:0 15px;			
					font-size: 13px;
					line-height: 38px;  
					color:#707070;
					font-weight:400;
					border:$main_border;
					margin:4px; 
					text-transform:capitalize;			
					&:hover {
						background:#333333;
						border-color:#333333;
						color:#ffffff;
					}
				}
			}
		}
	}

/* end module tags */
/* module wishlist , compare*/

#_desktop_compare,#_mobile_compare {
	.compare_top {
		float: right; 
		margin-right:5px;
		height:40px; 
		a {
			font-weight: 400;
			color: #292929;
			background: transparent;
			text-shadow: none;
			cursor: pointer;
			line-height:40px;
			height:40px;
			width:40px;
			border-radius:100%;
			font-size: 0;
			display: inline-block;
			text-align:center;
			text-transform: capitalize;
			position:relative;
			&:hover {
				color:#ffffff;
				background:#000000;
			}
			i {
				font-size:19px;
				line-height:40px;  
			}
			#poscompare-nb {
				width: 16px;
				height: 16px;
				line-height: 16px;
				font-size: 12px;
				position: absolute;
				top: 4px;
				text-align: center;
				font-weight: 500;
				right: 2px;
				border-radius: 100%;
				-webkit-border-radius: 100%; 
			}
		}
	}
}
/* #_mobile_compare {
	display:inline-block;
	vertical-align:top; 
	.compare_top {
		display:inline-block;
		padding: 0 15px;
		line-height: 20px;
		height: 20px;
		position:relative;
		a {
			font-weight: 400;
			color: #292929;
			background: transparent;
			text-shadow: none;
			cursor: pointer;
			line-height: 20px;
			font-size: 14px;
			display: inline-block;
			text-transform: capitalize;
			i {
				display: inline-block;
				vertical-align: -1px; 
				margin-right:5px; 

			}
			#poscompare-nb {
				width: 18px;
				height: 18px;
				line-height: 18px;
				font-size: 12px;
				color: #292929;
				background: #e6e6e6;
				position: absolute;
				top: -9px;
				text-align: center;
				font-weight: 400;
				right: 0;
				border-radius: 100%;
				-webkit-border-radius: 100%;
			}
		}
	}
} */
#_desktop_wishtlist,#_mobile_wishtlist {
	.wishtlist_top {
		float: right; 
		margin-right:5px;
		height:40px; 
		a {
			font-weight: 400;
			color: #292929;
			background: transparent;
			text-shadow: none;
			cursor: pointer;
			line-height:40px;
			height:40px;
			width:40px;
			border-radius:100%;
			font-size: 0;
			display: inline-block;
			text-align:center;
			text-transform: capitalize;
			position:relative;
			&:hover {
				color:#ffffff;
				background:#000000;
			}
			i {
				font-size:19px;
				line-height:40px;
			}
			.cart-wishlist-number {
				width: 16px;
				height: 16px;
				line-height: 16px;
				font-size: 12px;
				position: absolute;
				top: 4px;
				text-align: center;
				font-weight: 500;
				right:2px;
				border-radius: 100%;
				-webkit-border-radius: 100%;
			}
		}
	}
}
/* #_mobile_wishtlist { 
	display:inline-block;
	vertical-align:top;
	.wishtlist_top {
		display:inline-block;
		padding: 0 15px;
		line-height: 20px;
		height: 20px;
		position:relative;
		a {
			font-weight: 400;
			color: #292929;
			background: transparent;
			text-shadow: none;
			cursor: pointer;
			line-height: 20px;
			font-size: 14px;
			display: inline-block;
			text-transform: capitalize;
				i {
				display: inline-block;
				vertical-align: -1px; 
				margin-right:5px; 

			}
			.cart-wishlist-number {
				width: 18px;
				height: 18px;
				line-height: 18px;
				font-size: 12px;
				color: #292929;
				background: #e6e6e6;
				position: absolute;
				top: -9px;
				text-align: center;
				font-weight: 400;
				right: 0;
				border-radius: 100%;
				-webkit-border-radius: 100%; 
			}
		}
	}
} */
/* end module wishlist , compare */
/* module social */
#header {
	.social_follow { 
		margin:0;
		float:right;
		h4 {
			display:none;
		}
		li {
			margin: 0 20px 0 0;
			&:last-child {
				margin-right:0; 
			}
			a {
				background:transparent;
				line-height:40px;
				height:40px;
				width:auto;
				border:none;
				border-radius:0;
				color:#929292;  				
				&:hover {
					background:transparent;
				}
				&:before {
					font-size:16px;  
				}
			}
			
		}
	}

}
.social_follow {  
	position:relative;
	font-size:0;
	line-height:1; 
	@media (max-width:767px) {
		margin-bottom:20px; 
		
	}
	h4 {
		font-size: 14px;
		line-height: 1;
		font-weight:600;
		color: #292929;
		margin: 0 0 20px 0;
		text-transform: capitalize;
	} 
	li {
		display:inline-block;
		margin: 0 10px 10px 0;
		line-height:1;
		background-image:none !important;
		padding:0;
		&:last-child {
			margin-right:0; 
		}
		a {
			position: relative;
			display: inline-block;
			vertical-align: top;
			color:#aaaaaa; 
			background:#ebebeb;
			font-size: 0;
			padding: 0;
			line-height:38px;
			height:40px;
			width:40px;
			text-align:center; 
			border:none;
			border-radius:100%;
			z-index: 1;
			@include pos-transition(300ms);
			&:hover {
				background:#292929;
				color:#ffffff;
			}
			&:before {
				padding:0 !important;
				font-size:16px;
				display: inline-block;
				font-family:"Ionicons"; 
				font-weight: normal;
				vertical-align:middle;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		&.facebook {
			a {
				
				&:before {
					content: "\f231";
				}
			}
		}
		&.twitter {
			a {
			
				&:before {
					content: "\f243";
				}
			}
		}
		&.rss {
			a {
			
				&:before {
					content: "\f23d";
				}
			}
		}
		&.youtube {
			a {
			
				&:before {
					content: "\f24c";
				}
			}
		}
		&.googleplus {
			a {
				
				&:before {
					content: "\f234";
				}
			}
		}
		&.pinterest {
			a {
				
				&:before {
					content: "\f2b1";
				}
			}
		}
		&.vimeo {
			a {
				
				&:before {
					content: "\f245";
				}
			}
		}
		&.instagram {
			a {

				&:before {
					content: "\f350";
				}
			}
		} 
	}
}
#footer .social_follow li a:hover { 
	color:#ffffff;
}
/* end module social */
/* module cart block */
#_desktop_cart_block { 
	float: right;
	position: relative;
	padding:0;
	height:auto;
	@media (max-width:991px ) {
		float:none;
		display:inline-block;
		width:100%;     
	}	
}
#_mobile_cart_block {  
}
.blockcart {
	float:right; 
	position:relative;
	margin-right:5px; 
	&:hover .button_cart {
		a.desktop,a.mobile {
			color:#ffffff;
			background:#000000; 
		}
	}
	.button_cart { 
		position:relative;
		display:inline-block; 
		position: relative;
		&:hover a {

		}
		a.desktop,a.mobile {
			display: block;
			position: relative; 
			font-size:14px;
			font-weight:600;
			text-align:center;
			line-height:40px; 
			height:40px;	 		
			color:#292929;
			width:40px;
			border-radius:100%; 
			i {
				font-size:19px;
				line-height:40px;
				font-weight:400;
				
			}
			.item_count {
				width:16px;
				height:16px;
				line-height:16px;
				font-size:12px;
				position: absolute;
				top: 4px; 
				text-align: center;
				font-weight: 500;
				right:2px;
				border-radius: 100%;
				-webkit-border-radius: 100%; 
			} 
		} 
		
	}
	.popup_cart {
		margin:0;
		@media (min-width:992px) {
		padding:15px 0 0 0; 
		}
		width:345px; 
		@media (max-width:479px) {
			width:270px;
		}
		top:100%;
		left: auto;
		right: 0;
		@media (max-width:400px) {
			-moz-transform: translateX(20%);
			-webkit-transform: translateX(20%);
			-o-transform: translateX(20%);
			-ms-transform: translateX(20%);
			transform: translateX(20%);
		}
		position:absolute;
		display:none;   
		z-index:1001; 
		text-align:left;
		.mini_cart_arrow {
			
		}
		.content-cart {
			background:#ffffff;
			border:$main_border; 
		}
		
		ul  {
			padding:0 30px;
			li {
				padding:30px 0;  
				border-bottom:$main_border;
				overflow: hidden;
				.img_content {
					float: left;
					margin: 0 10px 0 0;
					position: relative;
					width:85px;
					.product-quantity {
						position: absolute;
						top: 5px;
						left: 5px;
						min-width: 20px;
						line-height: 20px;
						-webkit-border-radius: 100%;
						-moz-border-radius: 100%;
						border-radius: 100%;
						padding:0;
						text-align: center;
						color: #ffffff;
						font-size: 12px;
						display:inline-block;
					}
				}
				.right_block {
					overflow: hidden;
					position: relative;
					padding: 0 20px 0 0;
					span {
						font-size:12px;
					}
					.product-name {
						display: block;
						color:#292929;
						text-transform: capitalize;
						font-size: 14px;
						line-height: 20px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis; 
					}
					.product-price {
						display: block;
						margin: 5px 0 0;
						font-size: 16px;
						font-weight: 400;
						color: #929292;
						font-family: 'Karla', sans-serif;
					}
					.remove-from-cart {
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						color:#888888;
						font-size:18px;
						&:hover {
							color:#292929;
						}
					}
					.attributes_content {
						display: block;
						font-size: 12px;
						line-height:20px;
						color: #969696;
						margin: 5px 0 0;
					}
					strong {
						font-weight:400;
					}
				}
			}
		}
		.price_content {
			overflow: hidden;
			padding: 30px;
			.price_inline {
				overflow: hidden;
				line-height: 25px;
				.label {
					float: left;
					font-weight: 600;
					color:#3e3d43;
					font-size:16px;
				}
				.value {
					float: right;
					font-weight: 400;
					color:#929292;
					font-size:16px;
					font-family: 'Karla', sans-serif; 
				}
				&.cart-total {

					.value {
						font-weight: 600;
						font-size:18px;
					}
					
				}
			}
		} 
		.checkout {
			padding:0 30px 30px 30px;
			a {
				display:block;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 12px;
				border:2PX solid #292929; 
				color: #292929;
				background:#ffffff;
				box-shadow: none;
				padding: 20px 15px;
				line-height:16px;
				border-radius:0;	 
				&:hover { 
					background:#292929;
					color:#ffffff; 
				}
			}
		}
	}
}
@media (min-width: 992px) { 
	.blockcart .popup_cart  ul{
		max-height: 271px; 
		overflow-y:auto;
		

	}
}

.blockcart .popup_cart  ul::-webkit-scrollbar-track
{
	background-color:#292929;
}

	.blockcart .popup_cart  ul::-webkit-scrollbar
{
	width: 4px;
	background-color: #292929;
}

	.blockcart .popup_cart  ul::-webkit-scrollbar-thumb
{
	background-color: rgba(46, 46, 46, 0.3); 
	border-radius: 10px;
}
	/* style layer cart */
	#blockcart-modal .modal-body {
		background:#ffffff;
		padding: 3.125rem 1.875rem;
	}
	#blockcart-modal .divide-right {
		border-right:$main_border;
		@media (max-width:767px ) {
			border:none; 
		}
		p , strong {
			color:#292929;
			font-size:14px;
			font-weight:600;
		}
	}
	#blockcart-modal .cart-content { 
		p ,strong{
			color:#292929;
			font-size:14px;
		}
		strong {
			font-weight:600; 
		}
	}
	#blockcart-modal .product-name {
		font-size: 16px;
		margin-bottom: 20px;
		color:#292929;
	}
	#blockcart-modal .modal-header {
		background: #044975;
	}
	/* end style layer cart */
#blockcart-modal button.close {
	color:#ffffff;
	opacity:1;
}	
#blockcart-modal .cart-content .btn {
    margin-top: 20px;
}	
/* end module cart block */ 

/* search block */
@media (min-width:992px) and (max-width:1199px) {
	.fixed_search {
		position:fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;	
	}
}
#_desktop_search_block {
	float:right; 
	margin-right:5px; 
}
.block-search  {
	
	position: relative;
	line-height:1; 
	z-index:10; 
	.dropdown-backdrop {
		z-index:0;
	}
	.search-icon {
		cursor: pointer;
		height:40px;
		line-height:40px;
		width:40px;
		border-radius:100%;
		color:#292929; 
		display:inline-block;
		text-align:center; 
		i {
			font-size:19px; 
			line-height:40px; 
		}
		&:hover {
			background:#000000;
			color:#ffffff;
		}
		&:before {
			content: "";
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(22,22,27,0.97);
			opacity: 0;
			visibility: hidden;
			-webkit-transform: scale(0.7);
			transform: scale(0.7);
			-webkit-transition: -webkit-transform 0.8s ease,-moz-transform 0.8s ease,-o-transform 0.8s ease,transform 0.8s ease,opacity 0.5s ease,visibility 0.5s ease;
			-moz-transition: -webkit-transform 0.8s ease,-moz-transform 0.8s ease,-o-transform 0.8s ease,transform 0.8s ease,opacity 0.5s ease,visibility 0.5s ease;
			transition: -webkit-transform 0.8s ease,-moz-transform 0.8s ease,-o-transform 0.8s ease,transform 0.8s ease,opacity 0.5s ease,visibility 0.5s ease;
		}
		&:after {
			-webkit-transform: scale(0);
			transform: scale(0);
			position: fixed;
			right: 45px;
			top: 47px;
			content: "\f404";
			font-size: 72px;
			display: inline-block;
			    font-family: "Ionicons"; 
			font-weight: normal;
			margin: 0 10px;
			vertical-align: -1px;
			color: white;
			z-index: 2;
		}
	}
	&.open {
		.search-icon:before {
			opacity: 1;
			visibility: visible;
			-webkit-transform: scale(1);
			transform: scale(1);
			z-index: 2;
		}
		.search-icon:after {
			-webkit-transform: scale(1);
			transform: scale(1);
			@include pos-transition(800ms);
		}
	}
}
.search_top {
	display: none;
    position: fixed;
    right: 0;
    top: 50%;
    left: 0;
    margin-top: -30px;
    padding: 0;
	background: transparent;
    z-index: 3;
	@media (min-width:992px) and (max-width:1199px) {
		z-index: 999;
	}
	border:0;
	form {
		position: relative;
		max-width: 70%;
		margin: 0 auto;
		.text_input {
			padding: 15px 70px 15px 0;  
			height: 50px;
			line-height:20px;
			width: 100%;
			z-index: 1;
			color: white;
			background: transparent;
			border: 0;
			border-bottom: 1px solid rgba(255,255,255,0.5);
			font-size:14px;
			@include placeholder {
				color: #bababa;
				font-size:12px; 
				line-height:20px;
			} 
		}
		button {
			background: transparent;
			display: block;
			position: relative;
			border: none;
			color: white;
			width: 60px;
			text-align: center;
			line-height: 50px;
			height: 50px;
			padding: 0;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0;
			cursor: pointer;
			i {
				font-size:22px; 
				display:block;
				line-height:24px; 
			}
			&:hover {

			}
		}
	}
}

@media (max-width:991px) { 
	.block-search  {
		float:none;
		display:inline-block;
		width:100%;
		margin:10px 0; 
		.search-icon {
			display:none;
		}
		.search_top  {
			position:static;
			margin:0;
			display:inline-block !important; 
			width:100%;
			form {
				position: relative;
				max-width: 100%;
				margin: 0 auto;
				.text_input {
					border:$main_border;
					color: #666666;
					padding-left:20px;
					@include placeholder {
						color: #666666;
						font-size:14px; 
						line-height:20px; 
					}
				}
				button {
					color:#333333;
				}
			}	
		}
		
	}
	
}
.ui-autocomplete {
	overflow:hidden;
	max-height: 300px;
	overflow-y:scroll;
	padding:10px;
	border:$main_border; 
	@media (max-width:360px) {
	max-width:300px !important;
	
	}
	li a {
		display:flex !important;
		align-items:center; 
		width:100%;
		overflow: visible;
		max-height: none;
		overflow-y: auto;
		margin-bottom:15px;
		border:none;
		&:last-child {
			margin-bottom:0; 
		}
		img {
			margin: 0;
			flex: 0 0 60px;
			width:80px; 
		}
		> div {
			overflow:hidden;
			padding: 0 20px;  
		}
		span {
			margin: 0;
			display:inline-block;
			font-size:12px;
			vertical-align:top;
			&.separator { 
				margin-left:5px;
				margin-right:5px;
			}
			&.product {
				font-size:12px;
			}
		}
		.price {
			margin-top:10px; 
		}
		
	}
}
/* end search block */

/*  module bestseller-products */
.pos_bestsellers_product{
	.js-product-miniature{
		display:inline-block;
		width:100%;
		margin-bottom:20px;
		.img_block {
			float:left;
			width:120px;
		}
		.product_desc {
			overflow:hidden;
			padding:15px 10px 0 10px; 
			text-align:left; 
		}
		
	}
}
/* end module bestseller-products */
/*  module recommended-products */
.recommended-product{ 
	.js-product-miniature{
		float:left;
		width:100%;
		clear:both; 
		margin-bottom:20px;
		.img_block {
			float:left;
			width:80px; 
		}
		.product_desc {
			overflow:hidden;
			padding:0 10px 0 10px; 
			text-align:left; 		
		}
		.product-price-and-shipping-top .discount-product,.manufacturer {
			display:none; 
		}
		
	}
	.owl-nav {
		> div {
			top:-58px; 
			transform: none;
			opacity:1; 
			&:before {
				font-size: 35px;  
			}
			
			@include pos-transition(300ms);	
			
			&.owl-prev {
				left:auto;
				right:20px; 
			}
			&.owl-next{
				right:0;  
			}
			
		}
	} 
}
/* end module recommended-products */


/*  module new-products */
.pos_new_product{ 

	
}
/* end module new-products */
/* module special-products */
.pos-special-products  {
	.js-product-miniature{
		border-width: 1px;
		border-style: solid;
		padding-bottom:30px;
		border-radius:5px; 
		overflow:hidden; 
		.product_desc {
			.product_name {
				font-size:20px; 
			}
		}
		.product-price-and-shipping .regular-price,.product-price-and-shipping .price {
			font-size: 21px; 
		}
	}
}
/* end module special-products */
/* countdown */

.is-countdown {
	display:inline-block;
	width:100%;
	position:relative; 
	line-height:1; 
	margin-top:30px; 
	.countdown-section {
		display: inline-block;
		text-align: center;	
		margin-right:10px;  
		&:last-child {
			margin:0;
		}
		.countdown-amount {
			display: block; 
			font-size: 16px;
			line-height:20px;
			color:#292929;
			background:#efefef;
			width:45px;
			height:45px;
			line-height:45px;
			font-weight: 400;
			position:relative;
			border-radius:100%; 
			position:relative;
			margin-bottom:5px;   

		}
		.countdown-period {
		    display: block;
			font-size: 10px;
			line-height: 20px; 
			color: #292929;
			text-transform:uppercase; 
			
		}
	}
} 
/* 	.countdown-show1 .countdown-section {
		width: 100%;
	}
	.countdown-show2 .countdown-section {
		width: 50%;
	}
	.countdown-show3 .countdown-section {
		width: 33.33%;
	}
	.countdown-show4 .countdown-section {
		width: 25%;
	}
	#product .countdown-show4 .countdown-section{width: auto;}
	.countdown-show5 .countdown-section {
		width: 19.5%;
	}
	.countdown-show6 .countdown-section {
		width: 16.25%;
	}
	.countdown-show7 .countdown-section {
		width: 14%;
	}  */

/* end countdown */

/* end module cate-products-slider */
.category-products-slider   {
	.category_products {
	}
	.pos_title_column {
		h2 {
			&:before {
				content:"";
				position:absolute;
				left:0;
				right:80px; 
				top:50%;
				border-bottom:$main_border;
			}
			span{
				display:inline-block;
				background:#ffffff;
				padding-right:20px;
				position:relative;
			}
		}
	}	
	.js-product-miniature{
		display:inline-block;
		width:100%;
		.img_block {
			float:left;
			width:100px;  
		}
		.product_desc {
			overflow:hidden;
			padding:10px;	
			text-align:left;
			.product_name {
				min-height: auto;
			}
		}
		
	}
	.owl-nav {
		> div {
			height:32px;
			width:32px;
			line-height:30px;
			top: -55px;
			transform: none;
			opacity:1; 
			&.owl-prev  {
				right: 53px;
				left:auto; 
			}
			&.owl-next {
				right: 15px;
			}
			&:before {
				font-size: 14px;  
			}	
		}
		
	} 	
}
/* end module cate-products-slider */
/* module listcategories */
.poslistcategories {
	.list-categories {
		position:relative;
		@include pos-transition(300ms);	
		&:hover {
			.thumb-category img{
				@include pos-transform(1.1);
				@include pos-transition(300ms);	
			}
			.desc-listcategoreis {
				-moz-transform: translateY(0px);
				-webkit-transform: translateY(0px);
				-o-transform: translateY(0px);
				-ms-transform: translateY(0px);
				transform: translateY(0px);
				@include pos-transition(300ms);	
				.number_product {
					opacity:1;
					@include pos-transition(300ms);	 
				}
			}
		}
		.thumb-category {
			overflow:hidden;  
			img {
				@include pos-transition(300ms);	 
				width:100%;
			}
		}
		.desc-listcategoreis {
			position:absolute;
			left:40px; 
			bottom:30px;
			-moz-transform: translateY(20px);
			-webkit-transform: translateY(20px);
			-o-transform: translateY(20px);
			-ms-transform: translateY(20px);
			transform: translateY(20px);	
			@include pos-transition(300ms);	
			.name_categories a{ 
				font-size:18px;
				font-weight:600;
				color:#292929; 
				display:block; 
				text-transform:uppercase; 
				margin-bottom:10px;
				line-height:1;
			}
			.number_product {
				color:#888888; 
				font-size:16px; 
				text-transform:capitalize; 
				line-height:1;
				opacity:0;
				@include pos-transition(300ms);	
			}
			
		}
	}
	
}

/*end  module listcategories */
/* module testimonials */
.testimonials_container {
	padding:65px; 
	background:#f6f6f6;
	@media  (max-width:767px) { 
		padding:30px; 
	}
	.item-testimonials {
		 position:relative;
		text-align:center;
		overflow:hidden;	
		.item {
			display:inline-block;
			width:100%;
			img {
				width:70px; 
				margin-bottom:30px; 
			}
			.content_author {
				@media  (min-width:768px) { 
				max-width:80%;
				margin:auto; 
				}
				p {
					margin:0;
				}
				.des_namepost {
					position:relative;
					font-size: 14px;
					font-weight:600;
					line-height:1;
					color:#292929; 
					display: block;
					text-transform:uppercase;
					display:inline-block;
					margin-bottom:15px;  
					&:hover {
						
					}
					
				}
				.des_email {
					font-weight: 400;
					font-size: 12px;
					line-height: 1;
					text-transform: capitalize;
					color: #929292;
				}
				.des_testimonial {
					font-weight: 400;
					font-size: 12px;
					line-height: 24px;
					text-transform: capitalize;
					color: #707070;
					padding-bottom:30px; 
					margin-bottom:30px; 
					position:relative;
					&:before {
						content: "\f347";
						font-size: 21px;
						display: inline-block;
						font-family: "Ionicons";
						position: absolute;
						bottom: -5px;
						left: 50%;
						color:#cecece;
						-moz-transform: translateX(-50%);
						-webkit-transform: translateX(-50%);
						-o-transform: translateX(-50%);
						-ms-transform: translateX(-50%);
						transform: translateX(-50%);
					}
				}
				
			}
			
		
		}
	} 
}
/* end module testimonials */
/* module instagram */
.item-instagram,#instagram_block {
	> a {
		display:block;
		position:relative;
		&:hover {
			&:before {
				opacity:1; 
			}
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,.1);  
			@include pos-transition(300ms);	
			opacity: 0;
			z-index: 1;
			pointer-events: none;
		}
	}
	.our_instagram {
		margin-top:10px;
		display:inline-block; 		
	}
}

/* module end instagram */
/*  module logo */
.pos_logo {
	border-top:$main_border;
	border-bottom:$main_border;
	padding:25px 0;
	.item-banklogo {
		position:relative;
		overflow:hidden; 
		&:hover {
			img {
				filter: none;
				-webkit-filter: grayscale(0);	  
				opacity:1;	
				@include pos-transition(300ms);					
			}
		}
		img {
			 filter: gray; /* IE5+ */
			-webkit-filter: grayscale(1); 
			opacity:0.5; 
			@include pos-transition(300ms);	
		}
		
	}
	
}
/* end module logo */
/* blog */
#module-xipblog-archive,#module-xipblog-single {
	#wrapper {
		> .container {
			background:#ffffff;
			margin-top:-80px;
			padding-top: 30px; 	
			position:relative;
			max-width:1230px;
			padding-left:30px;
			padding-right:30px;   
		}
	}
}
.home_blog_post_area {
	position:relative;
	display:inline-block;
	width:100%;
	.blog_slider .item{
		.blog_post {
			display:inline-block;
			width:100%;
			background:#ffffff;
			&:hover {
				
				
			}
			.post_content {
				padding:20px 0 0 0;  
			}
			img{
				width: 100%;
			}
			.post_title {
				margin:0;
				a{
					font-size: 16px;
					font-weight:500;
					line-height: 1;
					color: #292929;
					text-transform: capitalize;
					position:relative;
					display:block;
					margin-bottom:15px;	
				}
			}
			
			.post_meta{
				overflow: hidden;
				position:relative;
				> p {
					float:left;
					font-size:12px; 
					color:#929292;
					margin-right:5px; 
					&:last-child {
						margin:0;
					}
					a:hover{
						text-decoration:underline; 
					}
				}
			}
			.post_description{
				font-size: 14px;
				font-weight: 400;
				color: #929292;
				display: inline-block; 
				width: 100%;
				margin-top:30px;
				display:none;
			}
			.read_more a{
				position:relative;
				margin-top:30px;
				color: #292929;
				font-size: 14px;
				text-transform:capitalize;
				font-weight:500;
				border:2px solid #292929;
				line-height:40px;
				padding:0 20px;
				display:inline-block;
				&:hover {
					background:#292929;
					color:#ffffff;   
				}
			}
		}
	}
}
.home_blog_post_area  .blog_post .post_thumbnail { 
    position: relative;
	overflow:hidden;
	&:hover {
		img {
			-moz-transform: scale(1.1) rotate(0.05deg);
			-ms-transform: scale(1.1) rotate(0.05deg);
			-o-transform: scale(1.1) rotate(0.05deg);
			-webkit-transform: scale(1.1) rotate(0.05deg);
			transform: scale(1.1) rotate(0.05deg);
			@include pos-transition(300ms);
		}
	}
	img {
		@include pos-transition(300ms);
	}
}

.kr_blog_post_area .blog_post .post_thumbnail img {
    margin:0;
	width:100%;
}
.kr_blog_post_area .blog_post .post_content .post_title {

}
.kr_blog_post_area .blog_post .post_content .post_meta > p {
    font-size: 13px;
    text-transform: capitalize;
    padding-right: 10px;
    margin-right: 10px;
}
.kr_blog_post_area .blog_post .post_content .read_more a {
    color: #555555; 
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}
/* end blog */

/* module newletters */
.ft_newsletter {
	text-align:center;
	border-top:$main_border;
	padding:65px 0;
	@media (max-width:767px ) { 
		padding:30px 0;
	}
	.title_newsletter {
		margin-bottom:30px; 
		h2 {
			font-weight:500;
			font-size:24px;
			color:#292929;
			line-height:1;
		}
		.des_newsletter {
			font-size:13px;
			color:#707070;
			margin-bottom:10px; 
		}
	}
	
	form{
		position: relative;
		overflow: hidden;
		max-width:620px;
		margin:auto;
		.input-wrapper {
			input{
				height:55px;
				background:transparent;
				border:0;
				color:#929292;  
				padding: 0 130px 0 20px; 
				@media  (max-width:767px) {  
					padding: 0 100px 0 20px;
				}		
				line-height:32px;
				display: inline-block; 
				width:100%;
				font-size:12px;
				border: 2px solid #ebebeb;
				@include placeholder {
					color:#929292;
					opacity:1; 
				} 
			}
		}
		.btn{
			position: absolute;
			top:13px;
			right: 5px;
			text-transform: uppercase; 
			font-size: 0;
			background:transparent; 
			font-weight:600;
			font-size:12px;
			color:#292929; 
			border: 0;
			border-left: 2px solid #ebebeb;
			box-shadow:none;
			padding:0 60px; 
			@media  (max-width:767px) {  
				padding:0 30px; 
			}	
			line-height: 30px; 
			height:30px;
			border-radius:0;
			@include pos-transition(300ms); 
			&:hover {
				@include pos-transition(300ms);  
			}
		}
		
		&:hover {
			
		}
		.condition, .alert{
			margin: 10px 0 0;
			font-size: 12px;
			line-height: 24px;
			color: #bcbcbc;
			background: none;
			border: 0;
			padding: 0;
			&.alert-danger{
				color: #929292;
			}
			&.alert-success{
				color: #929292; 
			}
		}
	}
}

/* module end newletters */
/* popup newletters */

/* end popup newletters */
#instagram_block_home,.testimonials_container,.pos_logo,.recommended-product,.pos_bestsellers_product,.pos-featured-products,.tab-category-container-slider,.poslistcategories,.pos-special-products,.pos_new_product,.category_products  ,.home_blog_post_area ,.products-accessories,.categoryproducts{ 
	margin-bottom:70px;    
	@media (max-width:767px ) { 
		margin-bottom:30px; 
	}
}
/* static */

#_desktop_static {
	float:left; 
}
#_mobile_static {
	padding:5px 15px;
}
.static-nav {
	color:#929292;
	font-size: 12px;
	line-height:40px;  
	float:left;
}

.static_cms {
	position:relative;
	margin-bottom:50px; 
	.box_cms  {	
		position:relative;
		text-align:center;
		margin-bottom:20px; 
		img {
			margin-bottom:20px;
		}
		.txt_cms {			
			h2 {
				line-height:1;
				color:#292929;
				font-size:12px;
				font-weight:600;
				text-transform:uppercase; 
				margin-bottom:25px;     
			}	
			p {
				display:block;
				line-height:20px;  
				color:#929292;
				font-size:12px;
				letter-spacing:0.025em;  
			}
		}
		
	}
	
}
.banner-box {
	position:relative;
	overflow:hidden; 
	margin-bottom:30px; 
	
	&:hover {
		img {
			-moz-transform: scale(1.1) rotate(0.05deg);
			-ms-transform: scale(1.1) rotate(0.05deg);
			-o-transform: scale(1.1) rotate(0.05deg);
			-webkit-transform: scale(1.1) rotate(0.05deg);
			transform: scale(1.1) rotate(0.05deg);
			@include pos-transition(300ms);
		}		
	}  
	img {
		width:100%;
		@include pos-transition(500ms);
	}   

}

.home-banner {
	position:relative;
	display:inline-block;
	width:100%; 
	margin-bottom:40px; 
	@media (max-width:767px ) { 
		margin-bottom:0;  
	}
	
} 
.home-banner{
	.txt_cms {
		position:absolute;
		left:45px;
		top:50%;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		@media (max-width:991px ) {  
			left:30px; 
		}
		p{
			color:#292929;
			font-weight: 300;
			font-size:14px;
			text-transform: uppercase;
			margin-bottom:5px;
		}
		h2 {
			line-height: 1;
			color: #292929;
			font-size:28px;
			font-weight: 500;
			text-transform: capitalize;
			margin:0;
		}
		a{
			color:#808080;
			font-weight: 300;
			font-size:18px;
			display:inline-block;
			text-transform: capitalize;
			margin-top:25px;
			position:relative;
			&:after {
				font-size: 16px;
				font-family: "Ionicons";
				display: inline-block;
				margin-left: 7px;
				content: "\f362";
				vertical-align: -2px;
			}
		}
		
	}
	
} 
.banner-top {
	margin-bottom:50px; 
	.row {
		margin:0 -10px;
		.col {
			padding:0 10px;
		}
	}
	.banner-box {
		margin-bottom:20px;  
	}
	.txt_cms {
		position:absolute;
		left:30px;
		top:35px;
		@media (max-width:991px ) { 
			top:20px; 
			left:20px; 
		}
		p{
			color:#707070;
			font-size:12px;
			margin-bottom:5px;
		}
		h4 {
			line-height: 1;
			color: #292929;
			font-size: 18px;
			font-weight: 500;
			text-transform: capitalize;
			margin:0;
		}
		
	}
}

/* end static */

/* item product */
.pos_title,.pos_title_column {
	position:relative;
	display:block;
	margin-bottom:30px;
	h2 {
		margin: 0;
		position: relative;
		text-transform: capitalize; 
		font-weight:500;
		line-height:1;  
	}
	
}
.pos_title_column {
	text-align:left;
}
.desc_title {
	color: #929292; 
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	margin-top:5px;
}

.product-price-and-shipping-top .discount-product {
	position: absolute;
	top:20px !important;
	left:20px;
	display: inline-block;
	color:#ffffff;
	background:#292929;
	padding:0;
	width:50px;
	text-align:center;
	line-height:50px;
	height:50px;
	border-radius:100%;
	font-size:12px;
	font-weight: 400;
	text-transform:capitalize;
	text-align:center;
	z-index:4;
	
}
.product-flag  {
	.new,
	.on-sale,.pack{
		position: absolute;
		top:20px !important;
		left:20px;
		display: inline-block;
		color:#ffffff;
		padding:0;
		width:50px;
		text-align:center;
		line-height:50px;
		height:50px;
		border-radius:100%;
		font-size:12px;
		font-weight: 400;
		text-transform:capitalize;
		text-align:center; 
		z-index:1;  
	}
}
.product-flag .pack {
	z-index:2;
}
.product-flag .on-sale{
	z-index:3;
} 
.product-flag  .discount {
	display:none;
}
.owl-dots {
	text-align:center; 
	margin-top:20px; 
	.owl-dot  {
		display:inline-block;
		margin:0 5px;
		&:last-child {
			margin-right:0;
		}
		span {
			display:inline-block;
			width:12px;
			height:12px;
			border-radius:100%;
			background:#e7e7e7;
			@include pos-transition(300ms);
			
		}
		&.active,&:hover  {
			span{
				@include pos-transition(300ms);
			}
		}
	}
}
.owl-nav {
	> div {
		position:absolute;
		top:-70px; 
		display:inline-block;
		font-size:0;
		line-height:1; 
		cursor:pointer;
		text-align:center;
		color:#929292;
		background:#ffffff;     
		z-index:1; 
		width:38px;
		height:38px;
		border:$main_border;
		border-radius:100%; 
		line-height:36px; 
		@include pos-transition(300ms);	
		&:before {
			font-size: 14px; 
			display:block;
			font-family: "Ionicons";
		}
		&:hover {
			color:#ffffff;
			@include pos-transition(300ms);	
		}
		@include pos-transition(300ms);	
		
		&.owl-prev {
			right:63px;
			@media (max-width:1199px ) { 
			
			}
			&:before {
				content: "\f3d2";
			}
		}
		&.owl-next{
			right:15px; 
			@media (max-width:1199px ) {  

			}
			&:before {
				content: "\f3d3";
			}
		}
		
	}
	@media (max-width:767px ) { 
		display: none;
	}
} 
.pos_content {
	position:relative;
	&:hover {
		.owl-nav {
			> div {
				opacity:1; 
			}
		}
	}
	.js-product-miniature ,.thumb-category,.item_logo{
		img {
			display:none;
		}
	}
	.owl-item {
		.js-product-miniature,.thumb-category,.item_logo { 
			img {
				display:block;
			}
		}
	}
}
/* style product */
.one_line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
} 	

.js-product-miniature{
	position:relative;
	background:#ffffff;  
	z-index:1;
	margin-bottom:30px;
	@include pos-transition(300ms);
	&:last-child {
		margin-bottom:0; 
	}
	&:hover {
		z-index:3;
		@include pos-transition(300ms); 
	}
	.img_block {
		position:relative;
		overflow:hidden;
		img {
			width:100%;
			margin:0;
			@include pos-transition(300ms);
		}
		
		
	}
	.product_desc { 
		padding:20px 0 0 0;
		background:#ffffff;
		overflow:hidden;
		@include pos-transition(300ms);
		.manufacturer a {
			font-size:12px;
			text-transform:capitalize;
			color:#929292;
			line-height:16px;
			margin-bottom:15px; 
			display:block;
			float:left;
			letter-spacing:-0.025em;
			&:hover { 
				color:#292929; 
			}
		}
		h3 {
			font-weight:400;
			margin: 0;
			line-height: 1;  
			clear:both;
		}
		.product_name {
			line-height: 18px; 
			display: block;
			margin-bottom:15px;
			font-weight:500;
			&.one_line {
				min-height:auto; 
			}
			
		}
		
		.hook-reviews {
			display:block;
			float:right;
			@media (min-width:360px ) and (max-width:460px ) {   
				float:none;
				clear:both;
			}	
			line-height:1;
			position:relative; 
			margin-bottom:15px; 
			@include pos-transition(300ms);	
			.comments_note {
				direction:ltr !important;
				text-align:left;
				display: inline-flex;
				position:relative;
			}
			.star_content {
			    display: inline-block;
				
			}
			.nb-comments {
				font-size:12px;
				display:none;
			}
		}
		.product-desc {
			display:none; 
			color:#707070;
			font-size:12px;
			line-height:24px;
			li {
				color:#707070;
				font-size:12px;
				line-height:24px;  
			}
		}
		.availability {
			display:none;
			line-height:1;
			margin-bottom:30px;
			.availability-list {
				font-size: 13px;
				color: #929292;
			}
		}
		.variant-links {
			margin-top:15px;
			margin-top: 15px;
			background: transparent;
			padding: 0;
			min-height: auto;
			text-align: left;
			display:none;
			a {
				margin:0 5px 0 0;
				margin: 0 5px 0 0;
				border-radius: 100%;
				width: 15px;
				height: 15px;
			}
		}
	
		
	}
}
.product-price-and-shipping {
	font-family: 'Karla', sans-serif;
	line-height:1;
	.sr-only {
		display:none; 
	}
	.price {
		font-weight: 400;
		&.price-sale {
			font-weight:700;
		}
	}
	.regular-price { 
		color: #929292;
		font-weight:400;
		text-decoration: line-through;
		margin-right:5px;
		font-size:16px;	
	}
	 
}
/* end style product */
/* style_product_default */
.js-product-miniature.style_product_default{
	
	&:hover 	{
		.add-to-links {
		    opacity: 1;
			visibility: visible;
			-webkit-transform: translateY(0) translateZ(0);
			transform: translateY(0) translateZ(0);
		}
		@include pos-transition(300ms); 
	}
	.add-to-links {
		position:absolute;
		left:20px;
		right:20px;
		@media (min-width:360px ) and (max-width:400px ) {   
			left:10px;
			right:10px;  
		}
		bottom:20px;
		background:#ffffff; 
		padding: 15px 5px 15px 10px;
		font-size:0;  
		box-shadow: -1px 0px 1px 0px rgba(0, 0, 0, 0.1);
		visibility: hidden;
		opacity: 0;
		transform: translateY(25px) translateZ(0);
		-webkit-transform: translateY(25px) translateZ(0);
		perspective: 800px;
		-webkit-perspective: 800px;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		-webkit-transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
		-webkit-transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,transform .3s ease,-webkit-transform .3s ease;
		@media (min-width:360px ) and (max-width:575px ) {   
		}	
		li {
			float:right;
			display:inline-flex;
			line-height:1;
			@include pos-transition(300ms);
			@media (min-width:360px ) and (max-width:575px ) {  
			}	
			&:last-child {
				margin:0;
			}
			a {
				display: inline-block;
				background:#ffffff;
				color:#b4b4b4;
				padding: 0;
				border:none;
				line-height:25px;
				height:25px;
				width:25px;
				@media (min-width:992px ) and (max-width:1199px ) {  
				   
				} 
				@media (min-width:360px ) and (max-width:575px ) {      
				}	
				padding: 0;
				font-size:0;
				text-align:center;
				text-transform: capitalize;
				&:before {
					font-size: 18px;
					font-family: "Ionicons";
					display: block;
				}
				&:hover{
					@include pos-transition(300ms);		
				}
				&.js-poscompare-add{ 
					&:before {
						content: "\f4a8"; 
					}
					&.cmp_added {
						cursor: not-allowed;  
					}
				}
				&.addToWishlist {
					&:before {
						content: "\f387";
					}
				}
				&.quick_view {
					&:before {
						content: "\f4a4";  
					}
				}
			}
			&.cart {
				float:left;   
				button.ajax_add_to_cart_button,span.ajax_add_to_cart_button  {
					display: inline-block;
					background:#ffffff;
					color:#292929;
					font-weight:600;
					padding: 0;
					border:none;
					line-height:25px;
					height:25px;
					width:auto;
					cursor:pointer;
					font-size:14px;  
					text-transform: uppercase;
					position:relative;
					z-index:1;
					letter-spacing: -0.025em; 
					@media (min-width:360px ) and (max-width:575px ) {  
						width:25px;
						text-align:center; 
						font-size:0;
						letter-spacing:0; 
						font-weight:400; 
						&:before {
							font-size: 18px;
							font-family: "Ionicons";
							display: inline-block;
							content: "\f110";  
							
						}				
					}
						
					
					i {
						font-size:14px;
						margin-right:5px;
						display:none;
					}
					&.disabled {
						opacity:0.5;
					}
					&:hover{
						@include pos-transition(300ms);	
					
						
					}
				}
			} 
		}
	}
	
}
/* end style_product_default */
/* style_product1 */
.js-product-miniature.style_product1{

	&:hover 	{
		.add-to-links {
		    opacity: 1;
			visibility: visible;
			transform: translateX(0);
			-webkit-transform: translateX(0); 
		}
		.cart{ 
		    opacity: 1;
			visibility: visible;
			-webkit-transform: translateY(0) translateZ(0);
			transform: translateY(0) translateZ(0);
		}
		.product-price-and-shipping {
			opacity: 0;
			visibility: hidden;
			-webkit-transform: translateY(25px) translateZ(0);
			transform: translateY(25px) translateZ(0); 	
			white-space: nowrap;
		}
		@include pos-transition(300ms); 
	}
	.add-to-links {
		position:absolute;
		right:15px;
		top:10px;
		padding:5px; 
		background:#ffffff;  
		box-shadow: -1px 0px 1px 0px rgba(0, 0, 0, 0.1);
		visibility: hidden;
		opacity: 0; 
		transform: translateX(20px);
		-webkit-transform: translateX(20px);
		perspective: 800px;
		-webkit-perspective: 800px;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		-webkit-transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
		-webkit-transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,transform .3s ease,-webkit-transform .3s ease;
		li {
			float:left;
			clear:both;
			display:inline-flex;
			@include pos-transition(300ms);
			&:last-child {
				margin:0;
			}
			a {
				display: inline-block;
				background:#ffffff;
				color:#b4b4b4;
				padding: 0;
				border:none;
				line-height:30px;
				height:30px;
				width:30px;
				padding: 0;
				font-size:0;
				text-align:center;
				text-transform: capitalize;
				&:before {
					font-size: 18px;
					font-family: "Ionicons";
					display: block;
				}
				&:hover{
					@include pos-transition(300ms);		
				}
				&.js-poscompare-add{ 
					&:before {
						content: "\f4a8"; 
					}
					&.cmp_added {
						cursor: not-allowed;  
					}
				}
				&.addToWishlist {
					&:before {
						content: "\f387";
					}
				}
				&.quick_view {
					&:before {
						content: "\f4a4";  
					}
				}
			}
			
		}
	}
	.cart{
		position:absolute;
		left:0;
		right:0;
		bottom:0;  
		@media (min-width:360px ) and (max-width:575px ) {  			
		}		
		visibility: hidden; 
		opacity: 0;
		transform: translateY(25px) translateZ(0);
		-webkit-transform: translateY(25px) translateZ(0);
		perspective: 800px;
		-webkit-perspective: 800px;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		-webkit-transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
		-webkit-transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,transform .3s ease,-webkit-transform .3s ease;
		button.ajax_add_to_cart_button,span.ajax_add_to_cart_button  {
			display: inline-block;
			color:#292929;
			background:transparent;  
			font-size:14px;
			font-weight:600;
			padding: 0;
			border:none;
			line-height:1;
			width:auto;
			box-shadow: none;   
			padding:0; 
			cursor:pointer;
			font-size:14px; 
			text-transform: uppercase; 
			position:relative;
			z-index:1; 
			@media (min-width:360px ) and (max-width:575px ) {  
						
			}		
			
			i {
				font-size:14px;
				margin-right:5px;
				display:none;
			}
			&.disabled {
				opacity:0.5;
			}
			&:hover{
				@include pos-transition(300ms);	
			
				
			}
		}
	}
	.product-price-and-shipping {
		visibility: visible;   
		opacity: 1;
		-webkit-transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
		-webkit-transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
		transition: opacity .3s ease,visibility .3s ease,transform .3s ease,-webkit-transform .3s ease;
		
	}
}
/* end style_product1 */
/* style_product2 */
.js-product-miniature.style_product2{
	&:hover 	{
		.add-to-links li {
		    opacity: 1;
			visibility: visible;
			transform: translateX(0);
			-webkit-transform: translateX(0);  
		}
		
		@include pos-transition(300ms); 
	}
	.add-to-links {
		position:absolute;
		right:10px;
		top:10px;
		li {
			float:left;
			clear:both;
			display:inline-flex;
			@media (min-width:360px ) and (max-width:575px ) {  

			}	
			margin-bottom:5px; 
			visibility: hidden;
			opacity: 0; 
			transform: translateX(30px);
			-webkit-transform: translateX(30px);
			perspective: 800px;
			-webkit-perspective: 800px;
			backface-visibility: hidden;
			-webkit-backface-visibility: hidden;
			&:nth-child(1){
				-webkit-transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
				-webkit-transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
				transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
				transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
				transition: opacity .3s ease,visibility .3s ease,transform .3s ease,-webkit-transform .3s ease;
			}
			&:nth-child(2){
				-webkit-transition: opacity .5s ease,visibility .5s ease,transform .5s ease;
				-webkit-transition: opacity .5s ease,visibility .5s ease,-webkit-transform .5s ease;
				transition: opacity .5s ease,visibility .5s ease,-webkit-transform .5s ease;
				transition: opacity .5s ease,visibility .5s ease,transform .5s ease;
				transition: opacity .5s ease,visibility .5s ease,transform .5s ease,-webkit-transform .5s ease;
			}
			&:nth-child(3){ 
				-webkit-transition: opacity .7s ease,visibility .7s ease,transform .7s ease;
				-webkit-transition: opacity .7s ease,visibility .7s ease,-webkit-transform .7s ease;
				transition: opacity .7s ease,visibility .7s ease,-webkit-transform .7s ease;
				transition: opacity .7s ease,visibility .7s ease,transform .7s ease;
				transition: opacity .7s ease,visibility .7s ease,transform .7s ease,-webkit-transform .7s ease;
			}
			&:nth-child(4){
				-webkit-transition: opacity .9s ease,visibility .9s ease,transform .9s ease;
				-webkit-transition: opacity .9s ease,visibility .9s ease,-webkit-transform .9s ease;
				transition: opacity .9s ease,visibility .9s ease,-webkit-transform .9s ease;
				transition: opacity .9s ease,visibility .9s ease,transform .9s ease;
				transition: opacity .9s ease,visibility .9s ease,transform .9s ease,-webkit-transform .9s ease;
			}
			&:last-child {
				margin:0;
			}
			a {
				display: inline-block;
				background:#ffffff;
				color:#b4b4b4;
				padding: 0;
				border:$main_border;
				line-height:30px;
				height:30px;
				width:30px;
				@media (min-width:360px ) and (max-width:575px ) {  				
				}	
				padding: 0;
				font-size:0;
				text-align:center;
				text-transform: capitalize;
				box-shadow:none; 
				border-radius:100%; 
				&:before {
					font-size: 18px;
					font-family: "Ionicons";
					display: block;
				}
				&:hover{
					color:#ffffff; 
					@include pos-transition(300ms);		
				}
				&.js-poscompare-add{ 
					&:before {
						content: "\f4a8"; 
					}
					&.cmp_added {
						cursor: not-allowed;  
					}
				}
				&.addToWishlist {
					&:before {
						content: "\f387";
					}
				}
				&.quick_view {
					&:before {
						content: "\f4a4";  
					}
				}
			}
			&.cart{		
				button.ajax_add_to_cart_button,span.ajax_add_to_cart_button  {
					display: inline-block;
					background:#ffffff;
					color:#b4b4b4;
					font-size:14px; 
					font-weight:400;
					padding: 0;
					border:$main_border;
					line-height:30px;
					height:30px;
					width:30px;
					@media (min-width:360px ) and (max-width:575px ) {  
					}	
					box-shadow:none; 
					padding:0; 
					cursor:pointer;
					font-size:0; 
					text-transform: capitalize; 
					position:relative;
					z-index:1;
					border-radius:100%;
					&:before {
						font-size: 18px;
						font-family: "Ionicons";
						display: block;
						content: "\f110";  
						
					}
					i {
						font-size:14px;
						margin-right:5px;
						display:none;
					}
					&.disabled {
						opacity:0.5;
					}
					&:hover{
						color:#ffffff;
						@include pos-transition(300ms);	
					}
				}
			}
			
		}
	}
	
}
/* end style_product2 */
/* style_product3 */
.js-product-miniature.style_product3{
	&:hover 	{
		.add-to-links li,.cart { 
		   opacity: 1;
			visibility: visible;
			-webkit-transform: translateY(0) translateZ(0);
			transform: translateY(0) translateZ(0);
		}
		
		@include pos-transition(300ms); 
	}
	.add-to-links {
		position:absolute;
		right:0;
		left:0;
		bottom:15px;
		text-align:center; 
		li {
			display:inline-flex;
			vertical-align:top; 
			margin-right:3px;
			@media (min-width:360px ) and (max-width:575px ) {  
			}		
			visibility: hidden;
			opacity: 0; 
			transform: translateY(25px) translateZ(0);
			-webkit-transform: translateY(25px) translateZ(0); 
			perspective: 800px;
			-webkit-perspective: 800px;
			backface-visibility: hidden;
			-webkit-backface-visibility: hidden;
			&:nth-child(1){
				-webkit-transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
				-webkit-transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
				transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;
				transition: opacity .3s ease,visibility .3s ease,transform .3s ease;
				transition: opacity .3s ease,visibility .3s ease,transform .3s ease,-webkit-transform .3s ease;
			}
			&:nth-child(2){
				-webkit-transition: opacity .5s ease,visibility .5s ease,transform .5s ease;
				-webkit-transition: opacity .5s ease,visibility .5s ease,-webkit-transform .5s ease;
				transition: opacity .5s ease,visibility .5s ease,-webkit-transform .5s ease;
				transition: opacity .5s ease,visibility .5s ease,transform .5s ease;
				transition: opacity .5s ease,visibility .5s ease,transform .5s ease,-webkit-transform .5s ease;
			}
			&:nth-child(3){
				-webkit-transition: opacity .7s ease,visibility .7s ease,transform .7s ease;
				-webkit-transition: opacity .7s ease,visibility .7s ease,-webkit-transform .7s ease;
				transition: opacity .7s ease,visibility .7s ease,-webkit-transform .7s ease;
				transition: opacity .7s ease,visibility .7s ease,transform .7s ease;
				transition: opacity .7s ease,visibility .7s ease,transform .7s ease,-webkit-transform .7s ease;
			}
			&:nth-child(4){
				-webkit-transition: opacity .9s ease,visibility .9s ease,transform .9s ease;
				-webkit-transition: opacity .9s ease,visibility .9s ease,-webkit-transform .9s ease;
				transition: opacity .9s ease,visibility .9s ease,-webkit-transform .9s ease;
				transition: opacity .9s ease,visibility .9s ease,transform .9s ease;
				transition: opacity .9s ease,visibility .9s ease,transform .9s ease,-webkit-transform .9s ease;
			}
			&:last-child {
				margin:0;
			}
				a {
				display: inline-block;
				background:#ffffff;
				color:#b4b4b4;
				padding: 0;
				border:$main_border;
				line-height:30px;
				height:30px;
				width:30px;
				@media (min-width:360px ) and (max-width:575px ) {  				
				}	
				padding: 0;
				font-size:0;
				text-align:center;
				text-transform: capitalize;
				box-shadow:none; 
				border-radius:100%; 
				&:before {
					font-size: 18px;
					font-family: "Ionicons";
					display: block;
				}
				&:hover{
					color:#ffffff; 
					@include pos-transition(300ms);		
				}
				&.js-poscompare-add{ 
					&:before {
						content: "\f4a8"; 
					}
					&.cmp_added {
						cursor: not-allowed;  
					}
				}
				&.addToWishlist {
					&:before {
						content: "\f387";
					}
				}
				&.quick_view {
					&:before {
						content: "\f4a4";  
					}
				}
			}
			&.cart{		
				button.ajax_add_to_cart_button,span.ajax_add_to_cart_button  {
					display: inline-block;
					background:#ffffff;
					color:#b4b4b4;
					font-size:14px; 
					font-weight:400;
					padding: 0;
					border:$main_border;
					line-height:30px;
					height:30px;
					width:30px;
					@media (min-width:360px ) and (max-width:575px ) {  
					}	
					box-shadow:none; 
					padding:0; 
					cursor:pointer;
					font-size:0; 
					text-transform: capitalize; 
					position:relative;
					z-index:1;
					border-radius:100%;
					&:before {
						font-size: 18px;
						font-family: "Ionicons";
						display: block;
						content: "\f110";  
						
					}
					i {
						font-size:14px;
						margin-right:5px;
						display:none;
					}
					&.disabled {
						opacity:0.5;
					}
					&:hover{
						color:#ffffff;
						@include pos-transition(300ms);	
					}
				}
			}
		}
	}
	
	
}
/* end style_product3 */
#footer {
	padding:0;
	color:#707070;
	background:#f6f6f6;
	position:relative;
	p {
		line-height:24px; 
	}
	img {
		margin:0; 
	}
	.footer-container {
		margin: 0;
		padding: 0;
		overflow: visible;
		box-shadow: none;
		.footer_top {
			position:relative;
			background:#ffffff;
		}	
		.footer_bottom {	
			padding:70px 0 0 0;
			@media (max-width:767px) {
				padding:30px 0 0 0;
				
			}
			.row {
				margin-bottom:60px;
				@media (max-width:767px) {
					margin-bottom:30px;
					
				}
				&:last-child {
					margin-bottom:0;
				}
			}
			.bottom-inner {
				padding:20px 0;
				border-top:$main_border;
				display:inline-block;
				width:100%;
				.copyright {
					line-height:30px; 
					color:#929292; 
					a {
						&:hover {
							text-decoration:underline;
						}
					}
				} 
				ul{
					float:right;
					@media (max-width:767px) {
						float:left;
						
					}
					li {
						margin:0;
						display:inline-block;
						vertical-align:top;
						float:left;
						&:first-child a{
							padding-left:0; 
						}
						&:last-child a{
							padding-right:0;
						}
						a {
							line-height:30px;
							padding: 0 20px;
							display: block;
							text-transform: capitalize;
							color: #929292;
							margin-bottom:0px;
							display: block;
							position: relative;
							@include pos-transition(300ms);
						}
					}
				}
			}			
		}
		.footer_about_us {
			.logo_footer {
				margin-bottom:30px;
			}
			.desc_info {
				line-height: 24px;
				color: #707070;
				letter-spacing:0.025em;
				margin-bottom:35px;
				max-width:480px;  
			}
		}
		
		.footer_block{
			margin-bottom:0;
			@media (max-width: 991px) {
				margin-bottom:15px;
				&.not_title {
					margin:0;
				}
				
			}
			@media (min-width: 768px) and (max-width: 991px) {
				&:nth-child(2n+1) {
					clear:both; 
				}
				width:50%;  
				&.col-md-12 {
					width:100%; 
				}
			}
			@media (max-width: 767px) {
				margin-bottom:0;
				.title {
					padding:0;
					border:none;
				}
				&.col-md-6 {
					width:100%;  
				}
			}
			h3{
				font-size:16px;
				text-transform: capitalize;
				margin-top: 0;
				margin-bottom:20px;
				letter-spacing: 0;
				position: relative;
				color:#292929;
				@media (max-width: 767px) {
					margin-bottom: 10px;
				}
			}
			.navbar-toggler {
				position: absolute;
				right: 15px;
				top: 0;
				width:auto;
				height:auto;
				color:#292929;
			}
			.footer_list    {
				margin-bottom:0;
				background:none;
				@media (max-width: 767px) {
					margin-bottom:20px;  
				}
				> li,> ul > li { 
					display:block;
					margin: 0;
					line-height:1;
					border:0;
					padding:0;
					font-weight:400;
					margin-bottom:10px; 
					&:last-child {
						margin-bottom:0; 
					}
					a {
						line-height:26px;
						padding: 0;
						display: block;
						text-transform: capitalize;
						color: #707070;
						margin-bottom:0px;
						display: block;
						position: relative;
						@include pos-transition(300ms);
						&:before {
							content: "\f105";
							font-family: "Font Awesome 5 Free";
							font-size: 14px;
							display:inline-block;
							margin-right:5px;
							font-weight:600;
							display:none;
						}
						&:hover {
							padding-left:10px;
							@include pos-transition(300ms);
						}
					
					}
				}
				.footer-time {
					li{
						&:last-child {
							margin-top:45px;  
							span {
								color:#292929;
							}
						}
						span {
							line-height:26px;
							padding: 0;
							display: block;
							text-transform: capitalize;
							color: #707070;
							margin-bottom:0px;
							display: block;
							position: relative;
							@include pos-transition(300ms);
							
						}
					}
				}
			}		
		}
		
	}
} 

/* end footer */
/* breadcrumb */
.breadcrumb_container[data-depth="1"] {
    display: none; 
}
.breadcrumb_container{
	position:relative;
	background: url(../img/bg_breadcrumb.jpg) no-repeat center; 
	background-size:cover;   
	padding:100px 0 200px 0;   
	
	.name_category {
		h2 {
			color:#333333;
			font-weight:500;
			font-size:36px;
			line-height:1;
			text-transform:capitalize; 
			margin:20px 0; 
		}
	}
	.breadcrumb {
		background: transparent;
		padding:0;
		margin:0;
		font-size:12px; 
		ol {
			padding-left: 0;
			margin-bottom: 0;
			li {
				display: inline;
				&:after{
					content: "/";
					color: #707070; 
					margin: 0.3125em;
					font-size:12px;
					vertical-align:-1px;
				}
				&:last-child {
					a ,span{
				
					}
					&:after{
						content: "";
					}
				}
				a{
					display: inline-block;
					position: relative; 
					color: #707070;
					line-height:25px;
					&:hover{ 
						
					}
				}
			}
		}
	}
	.breadcrumb-inner[data-depth="1"] {
		display: none;
	}
}
/* breadcrumb */

/* category */
#js-product-list-header.h2 {
	margin-bottom:15px; 
	text-transform:uppercase;
	font-weight: 500;
}
#js-product-list-top {
	ul.display  {
		float:left;
		margin-right:50px;
		position:relative;
		z-index:1;
		@media (min-width: 768px) and (max-width: 991px) {
			margin-right:10px;
		}
		@media (max-width: 767px) {
			margin:0;
		}
		li {
			float:left;
			line-height:30px;
			height:30px;  
			margin-right:15px;
			cursor:pointer;
		    color: #e2e2e2; 
			background:transparent; 
			&:last-child {
				margin-right:0; 
			}
			@include pos-transition(300ms);
			&:hover, &.selected{
				color:#292929;	
				@include pos-transition(300ms);
			}
			i {
				font-size:21px;
				line-height:30px; 
			}
		}
	}
}  
.products-selection p, .products-selection .sort-by {
    line-height: 20px;
    font-size: 14px;
    padding: 5px;
    margin: 0;
    float: left;
    color: #292929;
    font-weight: 400;
    text-transform: capitalize;
}
.products-selection{
	padding:15px 0;
	border-top:$main_border;
	border-bottom:$main_border;
	margin-bottom:30px; 
	@media (max-width: 400px){
		.sort-by-row {
			padding-top: 10px;
			width: 100%;
		}
	}
	p, .sort-by{
		line-height: 20px;
		font-size: 12px;
	    padding: 5px;
		margin:0;
		float:left;
		color: #707070;
		font-weight:400;
		text-transform: capitalize;
	}
	.products-sort-order .select-title{
		border:$main_border;
		padding: 2px 15px;
		line-height: 24px;
		font-size: 13px;
		margin:0;
		color: #707070;
		text-align:left;
		box-shadow:none;
		i {
			float:right;
		}
	}
	.products-sort-order .dropdown-menu{
		background: #fff;
		border: $main_border;
		right: 15px;
		left: 15px;
		width:auto;
		text-align:right;
		box-shadow: none;
		max-width:100%;
		.select-list{
			line-height: 24px;
			font-size: 12px;
			padding: 3px 15px;
			color: #707070;
			&:hover{
				color:#ffffff;
			}
		}
	}
}
.products-selection .filter-button .btn-secondary, .products-selection .filter-button .btn-tertiary {
	padding:3px 20px;  
}

.block-category {
	padding:0;
	min-height: auto; 
	margin-bottom:0; 
	background:transparent;
	 #category-description p{ 
		margin:0;  
	 }
	h1.h1 {
		color: #292929;
		font-size: 24px;
		line-height: 1;
		padding: 0 0 15px 0;
		margin: 0;
		border:none;
		text-transform: capitalize;   
		font-weight:500;
	}
	.category-cover {
		position:static;  
		margin-bottom:30px;  
		img {
			width:auto;
			height:auto;
			margin-bottom:30px;
		}
	}
	
}
#subcategories {
	margin-bottom:30px;  
	.subcategory-heading {
		font-size:18px;
		margin-bottom:20px;  
	}
	.subcategory-content { 
		text-align:center; 
		  margin:0 -15px;
		li {
			float:left;
			 padding:0 15px;
		}
		.subcategory-image {   
		    img {
				margin-bottom:20px;
				max-width:105px;
			}
			h5 a.subcategory-name {
				font-size:12px; 
				font-weight:400; 
			}
		}
		
	}
}
#products img, .featured-products img, .product-accessories img{
	margin: 0;
}
.block-categories, #search_filters{
	box-shadow: none;
    background: #ffffff;
	padding:0;
    padding-bottom:50px;
	border-bottom:$main_border;    
    margin-bottom: 50px;
}
.block-categories{
	a.h6{
		font-size: 20px;
		line-height: 1;
		font-weight:500;
		color:#292929;
		margin-bottom: 15px;
		position: relative;
		display:block;	
	}
	.category-sub-menu {
		margin:0;
		li {
			display:inline-block;
			width:100%;
			a{
				color: #707070;
				&:hover{
					 
				}
			}
		}
	}
	.category-sub-menu li[data-depth="0"] > a{
		font-weight:400;
		color: #707070; 
		display: block;
		font-size: 13px;
		text-transform:capitalize;
		line-height:35px;
		margin: 0;
		padding: 0;
		border: none;
		&:hover {

		}
	}
	.category-sub-menu li[data-depth="1"] {
		margin:0;
	}
	.category-sub-menu li:last-child a{
		border-bottom:0;
	}
	.category-sub-menu .category-sub-link {
	    font-size: 13px; 
		line-height:35px;
		margin: 0;
		padding: 0 10px;
		display: block;
		color:#292929; 
		
	}
	.collapse-icons {
		top: 7px;
		right: -10px;
		height: auto;
		width: auto;
		i {
			font-size:18px;
			&:hover {
		
			}
		}
	}


}
.block-categories .category-sub-menu li:not([data-depth="0"]):not([data-depth="1"]):before {
	display:none;
}
#search_filters_wrapper #search_filters .h6{ 
	font-size: 20px;
	line-height: 1;
	font-weight:500;
	color:#292929;
	margin-bottom: 15px;
	position: relative;
	display:block;
}

#search_filters_wrapper #search_filters .facet { 
	padding-top:20px;
	.facet-title{
	    display: block;
		clear: both;
		color: #292929;
		font-weight:500; 
		font-size: 16px;
		padding:0;
		border:none;
		margin-bottom: 10px;
		line-height: 24px;
		text-transform:capitalize;  		
	}
	.collapse{
		margin: 0;
		li {
			line-height:25px;
		}
	}
	.custom-checkbox input[type="checkbox"] + span{
		margin: -3px 3px 0 0;
		width: 15px;
		height: 15px;
		border: 1px #dfdfdf solid;
		border-radius:3px;
		@include pos-transition(300ms);
		&.color{
			border: 0;
		}
	}
	.custom-radio {
		border: 2px solid #dfdfdf;  
	}
	.facet-label {
		margin:3px 0; 
		a{
		    color: #707070;
			font-size: 13px;
			margin-top:0;
			line-height:22px;
		}
		&:hover,&.active {
			a{
	
			}
			.custom-checkbox input[type="checkbox"] + span{
		
				@include pos-transition(300ms);
			}
		}
	}
}
.faceted-slider p {
	color:#292929;
	font-size:14px; 
}
#search_filters .ui-slider-horizontal {
    height: 5px;
	border:none;
    margin-top: 20px;
	background: #dcdcdc;
	margin-right:16px;
}

#search_filters .ui-slider .ui-slider-handle {
    top: -6px;
    width: 16px;
    height: 16px;
    border: 1px solid #eee;
    border-radius: 100%;
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);  
}
/* Drop-down list */
.facet-dropdown {
    color: #292929;
    background:#ffffff;
    border:none;
    box-shadow: none;
}
.facet-dropdown .select-title {
	border:$main_border;
    color: #292929;
	padding: 5px 10px;
    border-radius: 3px;
    background: #ffffff;
    background: -moz-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: -webkit-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: linear-gradient(to bottom, #ffffff 1%, #f3f3f3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
}
.facet-dropdown.open>.select-title {
    border:$main_border;
    background: #ffffff;
    background: -moz-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: -webkit-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: linear-gradient(to bottom, #ffffff 1%, #f3f3f3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
}
.facet-dropdown .dropdown-menu {
	border:$main_border;
     background: -moz-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: -webkit-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: linear-gradient(to bottom, #ffffff 1%, #f3f3f3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
    box-shadow: none;
}
.facet-dropdown .select-list {
    display: block;
    color: #292929; 
    font-size:14px;
	background:transparent;
	padding: 5px 10px; 
}
/* end Drop-down list */
/* page list */
.pagination .page-list {
    box-shadow: none;
	padding: 0;
}
.pagination{
	padding:0;
	background:#ffffff;
	font-size: 15px;
    font-weight: 400;
	margin:0 0 60px 0;  
    color: #292929;
	text-align:center;
	.previous,.next {
	   float:none;
	   width: auto;
	   display: inline-block;
	    padding: 0;
		font-size: 0;
		height:36px;
		line-height:36px;
		width:36px;
	   &:hover {
		
	   }
	}
	a{
		font-weight: 400;
		color: #292929;
		padding: 0;
		height: 36px;
		line-height: 36px;
		background: #f1f1f1;
		font-size: 15px;
		display: inline-block;
		width: 36px;
		border-radius: 100%;
		text-align: center;
		vertical-align:top;
		i {
			font-size: 18px;
		}
		&:hover{
			color:#ffffff; 
		}
	}
	.disabled{
		color: #292929;
	}
	.current a{
		font-size: 14px;
		color:#ffffff; 

	}
	.show_items {
		line-height:28px;
		font-size: 15px; 
	}
	>div:first-child { 
		line-height: 36px; 
	}
}
/* grid list */
#js-product-list {
	.product_content { 
		position:relative;
		background:#ffffff; 
		&.list {
			
			.js-product-miniature { 
				box-shadow:none;
				margin-bottom:30px;
				.row {
					margin:0; 
				}
				.img_block  {
					padding:0;
					overflow:hidden;					
				}
				.product_desc  {
					padding-left:30px; 
					padding-top:0;
					padding-bottom:0;
					text-align:left;
					margin:0 !important;
					@media (max-width: 479px) {
						padding:20px 0;  
					}
					.product_name {
						min-height:auto; 
					}	
					.hook-reviews {
						float:none; 
					}	
					.product-desc {
						display:block;
						padding: 30px 0;
						border-top:$main_border;
						margin: 0;
						p {
							font-size:12px;
							color: #707070;
							line-height: 24px;
							padding: 0;		
						}
					}
					.availability {
						display:block;
						margin-top:20px;
					}

				}
				@media (max-width: 479px) {
					.img_block ,.product_desc {
						width:100%;
					}
				}
			}
			.js-product-miniature.style_product_default {
				.img_block  {
					.add-to-links {
						
					}
				}
				.product_desc .add-to-links {
					display:none; 
				}
					
			}
			.js-product-miniature.style_product1 {
				.img_block  {
					.add-to-links {
						display:none;     
					}
				}
				.add-to-links {
					position: static;
					visibility: visible;
					opacity: 1;
					transform: none;
					-webkit-transform: none; 
					display: inline-block;  
					box-shadow:none;
					padding: 0 5px;	
					li {
						clear: none;
					}
					
				}
				.cart {
					position: static;
					visibility: visible;
					opacity: 1;
					transform: none;
					-webkit-transform: none; 
					display: inline-block; 
					float: left;
					margin-right: 10px;	
					button.ajax_add_to_cart_button, span.ajax_add_to_cart_button {
						line-height: 30px; 
					}	
				}
				.product-price-and-shipping {
					opacity:1;
					-webkit-transform: none;
					transform: none;
					visibility: visible;
				}
			}
			.js-product-miniature.style_product2 {
				.img_block  {
					.add-to-links {
						display:none;     
					}
				}
				.add-to-links {
					position: static; 
					li {
						clear:none; 
						margin:0 2px 0 0; 
						opacity: 1;
						visibility: visible;
						transform: none; 
						-webkit-transform: none;
						&:last-child {    
							margin:0; 
						}
					}
				}
					
			}
			.js-product-miniature.style_product3 {
				.img_block  {
					.add-to-links {
						display:none;     
					}
				}
				.add-to-links {
					position: static; 
					text-align:left; 
					li {
						clear:none; 
						opacity: 1; 
						margin:0;
						visibility: visible;
						transform: none; 
						-webkit-transform: none; 
						
					}
				}
					
			} 
			
		}
		&.grid {
			.item-product {
				margin-bottom:60px;  
				-webkit-animation-fill-mode: none;
				animation-fill-mode: none;
				@media (min-width: 480px) and (max-width: 543px) {
					width:50%;
				}
				
			}
			@media (min-width:992px) {  
				.product_per_3:nth-child(3n+1)  {
					clear:both;
				}
			}
			@media (min-width:360px) and  (max-width: 991px) {
				.product_per_3:nth-child(2n+1)  {
					clear:both;
				}
			}
			@media (max-width: 359px) {
				.product_per_3  {
					clear:both; 
				}
			}
			@media (min-width:1200px) {  
				.product_per_4 {
					&:nth-child(4n+1)  { 
						clear:both;	
					}	
				}
			}
			@media (min-width:992px)  and  (max-width: 1199px){     
				.product_per_4:nth-child(3n+1)  {
					clear:both;
				}
			}
			@media (min-width:360px) and  (max-width: 991px) {
				.product_per_4:nth-child(2n+1)  {
					clear:both;
				}
			}
			@media (max-width: 359px) {
				.product_per_4  { 
					clear:both;
				}
			}
			@media (min-width:1200px) {  
				.product_per_5 {
					width:20%; 
					&:nth-child(5n+1)  {
						clear:both;	
					}								
				}
			}
			@media (min-width:992px)  and  (max-width: 1199px){     
				.product_per_5:nth-child(3n+1)  {
					clear:both;
				}
			}
			@media (min-width:360px) and  (max-width: 991px) {
				.product_per_5:nth-child(2n+1)  {
					clear:both;
				}
			}
			@media (max-width: 359px) {
				.product_per_5  {  
					clear:both;
				}
			}
		}
	}
} 

@media (min-width:360px) and (max-width:543px) { 
	#js-product-list .product_content.grid .item-product {  
		float:left;
		width:50%;    
	}
}
/* end grid list */
/* end category */
/*  */
/* page */
.clearfix {
	clear:both; 
}
#wrapper,#notifications,#footer {
	display:block !important;  
}
#wrapper{
	margin: 0;
	background:transparent;
	box-shadow: none;
	padding-top:30px; 
	&.wrapper_top {
		padding-top:0;
		> .container { 
			background:#ffffff;
			margin-top:-80px; 
			padding-top: 30px; 	
			position:relative;
			max-width:1230px;
			padding-left:30px;
			padding-right:30px;
		}
	}
}
#index #wrapper{
	padding-top:0; 
	#main .page-footer {
		margin:0;  
	}
}

/* end page */
/* detail */
#product #content {
    max-width:100%; 
}
.images-container,.images-quickviews {
	max-width:100%; 
	margin:auto;
	position:relative; 
	margin-bottom:30px;   
	img {
		display:none;
	}
	.slick-slider img {
		display:block;   
	}
}
.product-images {
	max-width:85%;
	margin:auto; 
	.slick-prev, .slick-next {
		&:before {
			color:#777777;
			opacity:1;
		}
	}
	.thumb-container {
		position:relative;
		cursor:pointer; 
		> div {
			margin:0 5px; 
			position:relative; 
			opacity:0.6;
			@include pos-transition(300ms);
			&:before {
				content:"";
				position:absolute;
				left:0;
				right:0;
				top:0;
				bottom:0;
				border:1px solid transparent; 
				pointer-events: none;
				@include pos-transition(300ms);
			}
		}
		&.slick-current > div,&:hover > div  {
			opacity:1;
			@include pos-transition(300ms); 
			&:before {
	
			}			
		}
		img {
			width:100%;  
		}
	}
	
}
/* vertical left */
.left_vertical,.right_vertical {
	.product-images {
		max-width:100%;
		.thumb-container {
		
			margin:0;   
			img {
				margin-bottom:10px;  
				display:none;
			}
			&.slick-slide {
				img {
					display:block;   
				}
			}
		}
	}
	.product-view_content {
		overflow:hidden;
		position:relative; 
	}
}
.left_vertical {
	.product-images {
		float: left;
		width: 98px;
		margin: 0;
		margin-right: 10px;
		
	}
}
.right_vertical {
	.product-images {
		float: right;
		width: 98px;
		margin: 0;
		margin-left: 10px; 
	}
}
/* end vertical left */

.product-cover {
	cursor:pointer; 
	img {
		box-shadow:none;
		    
	}
	@include pos-transition(300ms);
	&:before { 
		font-family: "Ionicons";
		content: "\f25e";
		position: absolute;
		bottom: 20px;
		right: 20px;
		color: #e2e2e2;
		z-index: 1;
		width: 50px;
		height: 50px;
		background: rgba(255, 255, 255, 0.7);
		line-height: 50px;
		font-size: 50px;
		text-align: center;
		cursor: pointer;
		border-radius: 5px; 
		pointer-events:none;
		opacity:0;	
		@media (max-width:991px) {    
			opacity:1; 
		}
	} 
	&:hover {
		&:before { 
			opacity:1; 
			background: rgba(255, 255, 255, 1);
			@include pos-transition(300ms);
		}
	}	
} 

.h1.namne_details,.product_name_h1 {
    line-height: 1;
	font-weight:500;
    margin:0 0 20px 0; 
}
.reference {
	font-size:12px;
	color:#adadad;
}
.product-prices {
	margin:10px 0 15px 0;
	font-family: 'Karla', sans-serif;
	.price,.current-price span:first-child{
	    font-weight: 700;
		line-height:30px;  
	}
}
.product-discount {
    display:inline-block;
}
.product-discount .regular-price {
	font-weight: 400;
    line-height:20px;
    color: #929292; 
	font-size: 16px;
}
.has-discount.product-price, .has-discount p {
	font-weight: 400;
    font-size: 16px;
    line-height:20px;
    display: inline-block;
    color: #929292;
}
.has-discount .discount {
    font-weight: 400;
    font-size:12px;
    line-height: 25px;
    color: white;
    padding: 0 10px 0 10px;
    background: #292929; 
    vertical-align: 4px;
    border-radius: 5px;
	text-transform:capitalize;  
}
.product-information {
    color: #707070;
    font-size:12px;
    font-weight: normal;
	line-height:24px; 
	.control-label {
		font-size:12px;
		color:#292929;
	}	
		.product-desc {
		padding:25px 0;
		p{
			font-size:12px;
			color: #707070;
			margin: 0;  
		}
	}
	label {
		margin:0;
	}
	.product-variants {
		margin-bottom:10px;
	}
}
.product-description-short{   
	padding:30px 0 0 0;
	margin:15px 0; 
	border-top:$main_border; 
	display: inline-block;
    width: 100%;
	letter-spacing:0.025em; 
}
.product-actions {
	padding-top:15px; 
}
.bootstrap-touchspin ,.product-variants>.product-variants-item select{
   box-shadow: none;
 
}
.product-information .product-variants {
 
}
.product-variants>.product-variants-item {
    margin: 0;
	display:flex;
	margin-bottom:20px;
	&:last-child {
		margin-bottom:0;   
	}
	.control-label {
		flex: 0;
		margin: 0 30px 0 0;
		line-height: calc(3.4rem - 2px);  
	}
	select{
		flex-grow: 1;
		&.form-control:not([size]):not([multiple]) {
			height: calc(3.4rem - 2px);
		}
	}
	label {
		margin:0;
	}
} 
.product-prices div {
	margin:0; 
}
.product-variants > .product-variants-item ul li {
    padding-top: 16px; 
}
.product-discounts {
	margin:0;
}
.product-quantity {
	display:block;
	.qty {
		margin-right: 20px; 
	}
	.add {
		@media (max-width: 360px) { 
			clear:both;
			margin-top:10px;
		}
	}
}
.product-quantity #quantity_wanted {
    height:50px;
	width:80px; 
	border:$main_border;
}
.product-quantity .btn-touchspin {
    height:26px; 
	border:$main_border;
	&:hover {
	   
	} 
}
.product-actions .product-add-to-cart {
	line-height:1;
	padding-top: 15px; 
	.control-label {
		display:none;
	}
}
.product-actions .add-to-cart {
	position: relative;
    padding: 0 35px; 
	@media (max-width:479px) {
		padding: 0 30px ;
	}	
	background:#ffffff;
    line-height:46px;
	height:50px;
    font-size: 12px;
    font-weight: 600;
    border:2px solid;
    border-radius: 0; 
	box-shadow: none;
	text-transform: uppercase;  
	&:hover {
		color:#ffffff;
	}
	&:before {
		content:"+";
		display:inline-block;
		vertical-align:middle;
		margin-right:5px;
		vertical-align:-1px;
	}	
	&:before {
		font-size: 20px;
		font-family: "Ionicons";
		display:inline-block;
		vertical-align:middle;
		margin-right:10px;
		vertical-align:-2px;
		content: "\f110";  
		font-weight: 400; 
	}
	i {
		display:none;
	}
}
#block-reassurance { 
	box-shadow: none;
	margin-top:10px;
	display:inline-block;
	width:100%; 
	box-shadow: none !important;
	border-top: $main_border; 
    padding-top: 30px;
    margin: 0;
	ul {
		
		li {
			border: none;
			display: inline-block;
			width:100%;
			margin-bottom:20px;
			&:last-child {
				margin-bottom:0;  
			}
			
			.block-reassurance-item {
				padding: 0;
				img {
					float:left;
					width:auto;
					margin-right:10px; 
				}
				span {
					font-weight: 400;
					font-size:12px;
					color:#707070; 
					display: block;
					overflow: hidden;
					padding-right:10px;
					font-weight: 400 !important;
				}
			}
		} 
	}
}
.quickview .social-sharing {
    margin-top: 30px;
}
.social-sharing  {
	display:inline-block;
	width:100%; 
	margin-top:30px;
	> span {
		display: inline-block;
		float: left;
		margin-right: 10px;
		line-height: 50px;
		font-weight: 400;
		color: #292929;
		font-size:12px;
	}
	ul {
		font-size:0;
		overflow:hidden; 
	}
}
.social-sharing li {
    box-shadow:none;
    height:auto;
    width: auto;
    border-radius:0;
	margin:0;  
	margin-bottom:10px; 
    display: inline-block;
    background: transparent !important;   
	line-height:1;
	@include pos-transition(300ms);
	&:before {
		display:none; 
	}
	&:first-child {
		margin:0;
	}
	&:hover {
		a {
	
			
		}
	}
	a {
		display: inline-block;
		line-height:46px;
		height:50px;
		width:50px;
		border:$main_border;
		border-radius:100%;
		text-align:center;
		color:#afafaf;  
		margin-right:10px;
		@media (max-width: 479px) {
			margin-right:10px;
		}	
		white-space: normal;
		text-indent:0; 
		overflow: hidden;
		font-size: 0;
		padding:0;
		&:before {
			font-family: "Ionicons";
			font-size: 14px;
			display:inline-block;
			background:transparent;
		}
		
	}
	&.facebook a {
		&:before {
			content: "\f231";
		}
	}
	&.twitter a {
		&:before {
			content: "\f243";
		}
	}
	&.googleplus a{
		&:before {
			content: "\f34f";
		}	
	}
	&.pinterest a {
		&:before {
			content: "\f2b1";
		}
	}
}
.tabs {
    box-shadow: none;
	display:inline-block;
	width:100%;
	margin:60px 0;
	padding:0;
	position:relative;
	background:#ffffff;
	text-align:center;
	position:relative;
	
	.nav-tabs {
		border:none;
		border-top:$main_border; 
		padding-top:60px;
		position: relative;
		display: block;
		margin-bottom:40px; 
		.nav-item {
			position: relative;
			display: inline-block;
			margin:0;
			float:none;
			
			.nav-link {
				background:transparent;
				border:0;
				text-transform: capitalize;
				line-height:24px;
				color: #929292; 
				padding:10px 0 0 0;
				margin: 0 30px;
				font-size: 18.5px;
				font-weight:500; 
				position:relative;
				@include pos-transition(300ms);
				span {
					position:relative;
					z-index:1;
				}
				&:hover,&.active {
					color:#484848;
					@include pos-transition(300ms);
				 
				}
			}
			@media (max-width: 639px) {
				width:100%;
				margin-bottom:10px;
				&:last-child {
					margin-bottom:0;
				}
				.nav-link {
					margin:0; 
				} 
			}
		}
	}
}
.tab-content {
    overflow: hidden; 
    font-size:12px;
	background:#ffffff;
	line-height:24px;
	text-align:left;
}
.tabs-left {
	text-align:left; 
	margin:90px 0; 
	.nav-tabs {
		@media (min-width: 640px) {
		float:left;
		width:250px;  
		}
		.nav-item {
			display:block;
			.nav-link {
				padding:10px 0;
			}
		}
	} 
	.tab-content {
		padding:10px 0;  
	}
}
.tabs-right {
	text-align:right; 
	margin:90px 0; 
	.nav-tabs { 
		@media (min-width: 640px) {
			float:right;
			width:250px; 
		}		
		.nav-item {
			display:block;
			.nav-link {
				padding:10px 0;
			}
		}
	} 
	.tab-content {
		padding:10px 0;  
		text-align:right; 
	}
}
.product-description  {
	font-size:12px;
    color: #707070; 
	P {
		font-size:12px;
		color: #707070; 
	}
}
#tab-content {
	font-size:12px;
	label {
		font-size:12px;
	}
}
.product-manufacturer {
	margin-bottom:30px;   
}
.quickview .modal-header {
    border: none;
    padding: 6px;
    height: 30px;
}
.quickview  .product-cover:before { 
	display:none;
}
.modal-body { 
}
.panel-product-line {
	display:inline-block;
	vertical-align:top; 
	float:left;
	#wishlist_button,.compare-button {
		line-height:30px;
		padding:0;
		border:none;
		color:#292929; 
		font-size: 12px;  
		background:transparent; 
		margin-right:15px; 
		cursor:pointer; 
		i {
			margin-right: 5px; 
			font-size: 18px;
			font-weight:400;
			vertical-align:-1px;  	 		
		}
		
		
	}
	
}
.quickview #product_comments_block_extra .comments_advices {
    display: none;
}
@media (max-width: 767px){
	#category #left-column #search_filters .facet {
		padding: 0 10px;
	}
}
.brand {
	margin-bottom:30px;
	display:flex;
	align-items:center;
	> div a{
		margin-right:30px; 
		font-size:14px;
		text-transform:capitalize;
		font-weight:500;
	}
}
/* end detail */
.cate_mobile .dropdown-backdrop {
	display:none;
}
.cate_mobile .dropdown-menu {
    min-width: 200px;
}